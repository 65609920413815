function checkPadding(el) {
    if ($(el).children().length === 1) {
        $(el).removeClass('cc-sliderPadding');
    } else if (!$(el).parents().hasClass('cc-hero')) {
        $(el).addClass('cc-sliderPadding');
    }
}

module.exports = function () {
    if ($('.cc-slider').length > 0) {
        $(window).on('resize', function () {
            $('.cc-slider').each(function () {
                $(this).on('breakpoint', function () {
                    checkPadding(this);
                });
            });
        });

        $('.cc-slider').each(function () {
            var existingSettings = $(this).data('slick') || {}; // Ottieni le impostazioni già presenti in data-slick
            if (typeof existingSettings === 'string') {
                existingSettings = JSON.parse(existingSettings); // Converti le impostazioni esistenti in un oggetto JSON
            }
            var newSettings = {
                prevArrow: '<button class="cc-sliderArrow cc-sliderArrow--prev"><div class="cc-svg cc-svg-sliderCaret cc-svg-sliderCaret--left"><span>Prev</span></div></button>',
                nextArrow: '<button class="cc-sliderArrow cc-sliderArrow--next"><div class="cc-svg cc-svg-sliderCaret cc-svg-sliderCaret--right"><span>Next</span></div></button>',
            };
            var combinedSettings = $.extend({}, existingSettings, newSettings); // Combina le impostazioni esistenti con le nuove impostazioni

            $(this).slick(combinedSettings); // Inizializza lo slick slider con le impostazioni combinate
            checkPadding(this);
        });

        $('a[data-toggle="tab"]').on('shown.bs.tab', function () {
            $('.cc-slider').slick('refresh');
        });
    }

    //Zoom on PDP image

    const $slider = $('.cc-slider');
    let isExpanded = false;
    let dragElement = { startPositionX: 0, startPositionY: 0 };
    let dragDisplay = { x: 0, y: 0 };

    function startInteraction(event) {
        if ($('.cc-imageCarousel').hasClass('cc-open')) {
            event.preventDefault();

            if (!isExpanded && !$(this).closest('.cc-imageCarousel__thumb').length) {
                $(this).addClass('expanded').css('transform', 'scale(2)');
                $slider.slick('slickSetOption', 'draggable', false);
                $slider.slick('slickSetOption', 'swipe', false);
                $slider.slick('slickSetOption', 'touchMove', false);

                dragElement.startPositionX = event.pageX;
                dragElement.startPositionY = event.pageY;

                $('.slick-arrow').each(function () {
                    $(this).attr('disabled', true).addClass('slick-disabled');
                });
                $('.slick-dots button').each(function () {
                    $(this).attr('disabled', true);
                });

                isExpanded = true;
            } else {
                $(this).removeClass('expanded').css('transform', 'scale(1)');
                $slider.slick('slickSetOption', 'draggable', true);
                $slider.slick('slickSetOption', 'swipe', true);
                $slider.slick('slickSetOption', 'touchMove', true);
                $('.slick-arrow').each(function () {
                    $(this).removeAttr('disabled').removeClass('slick-disabled');
                });
                $('.slick-dots button').each(function () {
                    $(this).removeAttr('disabled');
                });

                isExpanded = false;
            }
        }
    }

    function handleStop(event) {
        if ($('.cc-imageCarousel').hasClass('cc-open') && isExpanded) {
            dragElement.startPositionX = event.pageX - dragDisplay.x;
            dragElement.startPositionY = event.pageY - dragDisplay.y;
        }
    }

    function handleMove(event) {
        if ($('.cc-imageCarousel').hasClass('cc-open') && isExpanded) {
            const $activeImg = $('.slick-slide img.expanded');
            const maxHorizontalMovement = $activeImg.width() / 2;
            const maxVerticalMovement = $activeImg.height() / 2;
            const horizontalMovement = event.pageX - dragElement.startPositionX;
            const verticalMovement = event.pageY - dragElement.startPositionY;
            if (horizontalMovement < maxHorizontalMovement && horizontalMovement > -1 * maxHorizontalMovement) {
                dragDisplay.x = horizontalMovement;
            }
            if (verticalMovement < maxVerticalMovement && verticalMovement > -1 * maxVerticalMovement) {
                dragDisplay.y = verticalMovement;
            }
            $activeImg.css('transform', 'scale(2) translate(' + dragDisplay.x + 'px, ' + dragDisplay.y + 'px)');
        }
    }

    // Ascolta gli eventi hover del mouse e touch sui dispositivi mobili
    $slider.on('mouseenter', '.slick-slide img', startInteraction);
    $slider.on('touchstart', '.slick-slide img', startInteraction);
    // Ascolta gli eventi di movimento del mouse e tocco su dispositivi mobili
    $('body').on('pointermove', '.slick-slide', handleMove);
    $('body').on('pointerdown', '.slick-slide', handleStop);
    $('body').on('mousemove', handleMove);
    $('body').on('mousedown', handleStop);
};
