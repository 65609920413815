'use strict';

module.exports = function () {
    $('.js-loadMoreFaqs').on('click', function () {
        var showMoreUrl = $(this).data('url');
        $.spinner().start();

        $.ajax({
            url: showMoreUrl,
            method: 'GET',
            success(response) {
                if (response.htmlContent !== '') {
                    $('.cc-faq').find('.js-appendResult').last().append(response.htmlContent);
                    $('.js-loadMoreFaqs').data('url', response.newUrl);
                } else {
                    $('.cc-faq__loadMore').empty();
                }
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            },
        });
    });

    $(document).on('click', '.js-faqTarget', function () {
        var $faqPlus = $(this).find('.js-faqPlus');
        var $faqLess = $(this).find('.js-faqLess');
        var collapsed = $(this).hasClass('collapsed');

        if ($faqPlus.hasClass('d-none') && collapsed) {
            $faqPlus.removeClass('d-none');
            $faqLess.addClass('d-none');
        } else {
            $faqPlus.addClass('d-none');
            $faqLess.removeClass('d-none');
        }
    });
};
