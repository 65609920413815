'use strict';

module.exports = function () {
    $('body').on('click', '.js-nextRecordsUrl', function () {
        var offset = $('.js-nextRecordsUrl').attr('data-offset');
        var url = $(this).data('url') + '/?offset=' + offset;

        $.spinner().start();
        $.ajax({
            url: url,
            method: 'GET',
            success: function (data) {
                $('.order-list-container').append(data.htmlContent);
                if (data.offset) {
                    $('.js-nextRecordsUrl').attr('data-offset', data.offset);
                } else {
                    $('.cc-account__main__cta--loadMore').remove();
                }
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
        });
    });
};
