'use strict';

/* globals google */

//Variables for Google Autocomplete
var isAddressSelected = false;
var autocomplete;
var addressField;

const SHIPPING = 'shipping';
const BILLING = 'billing';

var formHelpers = require('base/checkout/formErrors');
var addressHelpers = require('../helpers/addressHelpers');

function initAutocomplete() {
    addressField = document.getElementById('address1');

    autocomplete = new google.maps.places.Autocomplete(addressField, {
        strictBounds: true,
        componentRestrictions: { country: ['it'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
    });

    // When the user selects an address from the drop-down, populate the  address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    isAddressSelected = true;
    var locality = '';

    //Clear input
    $('#addressNumber').val('');
    $('#address1').val('');
    $('#city').val('');

    // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
    for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case 'street_number': {
                document.querySelector('#addressNumber').value = component.long_name;
                break;
            }

            case 'route': {
                addressField.value = component.long_name;
                break;
            }

            case 'locality': {
                locality = component.long_name;
                break;
            }

            case 'administrative_area_level_3': {
                let fullCityName;
                fullCityName = locality + ', ' + component.long_name;
                if (component.long_name === locality || locality === '') {
                    document.querySelector('#city').value = component.long_name;
                    $('#city').attr('data-selectedcity', component.long_name);
                } else {
                    document.querySelector('#city').value = fullCityName;
                    $('#city').attr('data-selectedcity', fullCityName);
                }
                break;
            }
        }
    }
}

function disableAddressfields() {
    $('#address1').attr('disabled', true);
    $('#addressNumber').attr('disabled', true);
    $('#city').attr('disabled', true);

    $('#address1').addClass('cc-account__inputBorder');
    $('#addressNumber').addClass('cc-account__inputBorder');
}

function enableAddressfields() {
    $('#address1').attr('disabled', false);
    $('#addressNumber').attr('disabled', false);

    $('#address1').removeClass('cc-account__inputBorder');
    $('#address1').removeClass('is-invalid');
    $('#addressNumber').removeClass('cc-account__inputBorder');

    $('#addressNumber').val('');
    $('#address1').val('');
    $('#city').val('');
}

module.exports = function () {
    disableAddressfields();

    $('#zipCode').on('focus', function () {
        if (!$('#address1').attr('autocomplete')) {
            initAutocomplete();
        }
    });

    $('#zipCode').on('input', function () {
        if ($(this).val().length >= 5) {
            addressHelpers
                .getBoundsAutocompletePromise($(this).val())
                .then((results) => {
                    autocomplete.setBounds(results[0].geometry.bounds);
                    enableAddressfields();
                    $(this).removeClass('is-invalid').siblings('.invalid-feedback').html('');

                    //Script writing animation
                    const placeholderTexts = ['Via/Piazza...   ', '12   '];
                    const inputFields = [document.getElementById('address1'), document.getElementById('addressNumber')];

                    setTimeout(addressHelpers.writingAnimation(inputFields, placeholderTexts), 1000);
                })
                .catch(() => {
                    $(this).addClass('is-invalid').siblings('.invalid-feedback').html($(this).data('parse-error'));
                });
        } else {
            disableAddressfields();
        }
    });

    $('#address1')
        .on('blur', function () {
            if (!isAddressSelected) {
                $('#address1').val('');
            }
        })
        .on('change', function () {
            isAddressSelected = false;
        });

    $('.js-addressForm').submit(function (e) {
        var form = $(this);
        var redirectUrl = $('.js-saveAddress').data('redirecturl');
        e.preventDefault();
        var url = form.attr('action');
        var selectedCity = $('#city').attr('data-selectedcity') ? $('#city').attr('data-selectedcity') : $('#city').val();
        console.log(selectedCity);

        var formData = form.serialize() + '&dwfrm_address_city=' + selectedCity;
        form.spinner().start();
        e.stopImmediatePropagation();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success: function (data) {
                if (data.fields) {
                    var errors = [];
                    errors.push(data.fields);
                    errors.forEach(function (error) {
                        if (Object.keys(error).length) {
                            formHelpers.loadFormErrors('.js-addressFields', error);
                        }
                    });
                } else {
                    location.href = redirectUrl;
                }
                form.spinner().stop();
            },
        });
        form.spinner().stop();
        return false;
    });

    $('.remove-address').on('click', function () {
        var addresses = $('.cc-account__card').length;
        if (addresses === 1) {
            $('.js-noAddresses').removeClass('d-none');
        }

        // Update the delete modal title
        var $card = $(this).parents('.cc-account__card');
        var $deleteModalTitle = $('#deleteAddressModalTitle').data('base-label');
        if ($card && $card.hasClass('shipping-address')) {
            $deleteModalTitle += ' di consegna';
        } else {
            $deleteModalTitle += ' di fatturazione';
        }
        $('#deleteAddressModalTitle').html($deleteModalTitle);

        // Update the delete modal address summary
        var $addressInfo = $card.find('.cc-account__card__row__summary').html();
        $('.js-addressInfo').empty().html($addressInfo);
    });

    //On tab selection update the save button url
    $('.js-account-tab').on('click', function () {
        var type = this.dataset.type;
        var saveButton = $('.btn-save')[0];
        if (saveButton.href.includes('?')) {
            saveButton.href = saveButton.href.split('?')[0];
        }

        if (type === SHIPPING) {
            saveButton.href += '?type=' + SHIPPING;
        } else {
            saveButton.href += '?type=' + BILLING;
        }
    });
};
