'use strict';

jQuery(function () {
    $(document).on('trackingPreferenceExpressed', function (event, data) {
        var url = data.url.replace('consentPlaceHolder', data.consentTracking);
        console.log('conentTracking: ', url);
        $.ajax({
            url: url,
            type: 'get',
        });
    });
});
