'use strict';
const addressHelpers = require('../helpers/addressHelpers');

const resetFileName = 'nessun file selezionato';
var mappedCategories;
var filesArray = [];
//Variables for Google Autocomplete
var isAddressSelected = false;
var selectedCity = '';
var autocomplete;
var addressField;

// set up the Google autocomplete
function initAutocomplete() {
    addressField = document.getElementById('contactAddress1');

    // eslint-disable-next-line no-undef
    autocomplete = new google.maps.places.Autocomplete(addressField, {
        strictBounds: true,
        componentRestrictions: { country: ['it'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
    });

    // When the user selects an address from the drop-down, populate the  address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
}

// fills the form fields on address selection
function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    var cityArray = [];
    let address = '';
    isAddressSelected = true;
    selectedCity = '';

    //Clear input
    $('.cc-modal__input').addClass('cc-modal__input--noPlaceholder');
    $('#contactAddressNumber').val('');
    $('#contactAddress1').val('');

    // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
    for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case 'street_number': {
                document.querySelector('#contactAddressNumber').value = component.long_name;
                break;
            }

            case 'route': {
                address += component.long_name;
                break;
            }

            case 'locality': {
                selectedCity += component.long_name + ', ';
                cityArray.push(component.long_name);
                break;
            }

            case 'administrative_area_level_3': {
                selectedCity += component.long_name;
                cityArray.push(component.long_name);
                break;
            }
        }
    }
    //check on city
    if (cityArray[0] === cityArray[1]) {
        selectedCity = cityArray[0];
    }
    addressField.value = address + ', ' + selectedCity;
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');
    return newUrl;
}

//Base64 file convertion
function toBase64(file, filesLength) {
    var url = $('.js-fileUpload').data('url');
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function (evt) {
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: {
                base64: evt.target.result,
                fileName: file.name,
                filesLength: filesLength,
            },
            success: function (data) {
                if (data.error) {
                    $('.cc-file-upload__list').text(resetFileName);
                    $('.js-fileUpload').val('');
                    if ($('.case-submit-messages').length === 0) {
                        $('body').append('<div class="case-submit-messages"></div>');
                    }
                    $('.case-submit-messages').append(`<div class="alert alert-danger case-submit-alert text-center" role="alert">${data.messageError}</div>`);
                    setTimeout(() => {
                        $('.case-submit-alert').remove();
                    }, 5000);
                }
            },
        });
    };
}

// update the form fields shown on person type selection
function selectPersonType() {
    if ($('.cc-select .js-selectPersonType').val() === 'personaFisica') {
        $('.js-billingVat').addClass('d-none');
        $('.js-billingCompanyName').addClass('d-none');
        $('.js-billingTaxCode').removeClass('d-none');
        $('.js-billingTaxCode').find('.cc-formGroup').addClass('required');
    }
    if ($('.cc-select .js-selectPersonType').val() === 'personaGiuridica') {
        $('.js-billingVat').removeClass('d-none');
        $('.js-billingCompanyName').removeClass('d-none');
        $('.js-billingTaxCode').removeClass('d-none');
        $('.js-billingTaxCode').find('.cc-formGroup').removeClass('required');
    }
}

//Remove all invalid message accross the form
function removeInvalidation() {
    $('.cc-webToCase__invalid').hide();
    $('.form-control').removeClass('is-invalid');
    $('.custom-control-input').removeClass('is-invalid');
    $('.dropdown-toggle').removeClass('bs-invalid');
}

/**
 * Display error messages and highlight form fields with errors.
 * @param {string} parentSelector - the form which contains the fields
 * @param {Object} fieldErrors - the fields with errors
 */
function customLoadFormErrors(parentSelector, fieldErrors) {
    // Display error messages and highlight form fields with errors.
    $.each(fieldErrors, function (attr) {
        var element = $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr]);

        /*if element.length is 0, then the element is a rendered selectpicker
        -> logic to add invalid classes to the select*/
        if (element.length === 0) {
            $('*[name=' + attr + ']', parentSelector)
                .addClass('is-invalid')
                .parent()
                .parent()
                .find('.invalid-feedback')
                .html(fieldErrors[attr])
                .show();
            $('*[name=' + attr + ']', parentSelector)
                .siblings('.dropdown-toggle')
                .addClass('bs-invalid');
        }
    });
}

function displayRelatedReasons() {
    //Display only the options that are related to the selected category
    //If the category is for B2B, select the first reason
    var selectedCategory = $('.js-contactCategory').find(':selected');
    var isB2BCategory = selectedCategory.val() ? selectedCategory.val().toUpperCase().includes('B2B') : false;
    var validFor = selectedCategory.data('validfor');
    $('.js-contactReason option').each(function () {
        $(this).removeClass('d-none');
        if ($(this).data('validfor') && $(this).data('validfor').indexOf(validFor) === -1) {
            $(this).addClass('d-none');
        } else if (isB2BCategory) {
            var optionValue = $(this).val();
            if (optionValue !== '') {
                $('#contactReason').val(optionValue);
                isB2BCategory = false;
            }
        }
    });

    //Display the selector content
    $('.js-contactReason').selectpicker('refresh');
}

function checkFileDimensions(files) {
    var maxSize = 665600; // 650 KB to byte
    var sizeCheck = true;
    var notValidFiles = '';

    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        if (file.size > maxSize) {
            sizeCheck = false;
            notValidFiles += file.name + ' ';
        }
    }

    return { sizeCheck: sizeCheck, notValidFiles: notValidFiles };
}

function countCharacters(input) {
    var maxLength = parseInt(input.getAttribute('maxlength'));
    var currentLength = input.value.length;
    $(`#${input.id}-jsCharCount`).text(`${currentLength}/${maxLength} ${$(`#${input.id}-jsCharCount`).data('label')}`);
}

function showOrderField() {
    if ($('.js-contactCategory').find(':selected').data('showorderfield')) {
        $('.js-additionalField').removeClass('d-none');
        var orderNumber = $('.js-additionalField').data('ordernumber');
        if (orderNumber !== null && orderNumber !== 'null') {
            $('.js-additionalField').find('#contactOrderNumber').val(orderNumber);
        }
    }
}

//Show the warning message if the user is member, hide it otherwise
function handleB2BMember(isActivate) {
    if (isActivate) {
        $('.b2bWarning').removeClass('d-none');
        $('.cc-webToCase__submit').find('.cc-btn').attr('disabled', isActivate);
        $('.js-webToCaseMessage').addClass('d-none');
        $('.js-webToCaseAttachment').addClass('d-none');
    } else {
        $('.b2bWarning').addClass('d-none');
        $('.cc-webToCase__submit').find('.cc-btn').attr('disabled', isActivate);
        $('.js-webToCaseMessage').removeClass('d-none');
        $('.js-webToCaseAttachment').removeClass('d-none');
    }
}

//show the B2B form fields if the contact reason requires them
function showB2bFields() {
    if ($('.js-contactCategory').find(':selected').data('showb2bfields')) {
        var isMember = $('.js-b2bFields').data('ismember');
        if (isMember) {
            handleB2BMember(true);
        } else {
            $('.js-b2bFields').removeClass('d-none');
        }
    }
}

function enableAddressfields() {
    $('#contactAddress1').attr('disabled', false);
    $('#contactAddressNumber').attr('disabled', false);
}

function disableAddressfields() {
    $('#contactAddress1').attr('disabled', true);
    $('#contactAddressNumber').attr('disabled', true);
    $('#contactAddress1').val('');
    $('#contactAddressNumber').val('');
}

module.exports = function () {
    selectPersonType();
    //on page load show order field if present in querystring and count the characters of the custom inital message
    showOrderField();
    showB2bFields();
    var contactMsg = document.getElementById('contactMessage');
    if (contactMsg) {
        countCharacters(contactMsg);
    }

    $('#contactMessage').on('input onPaste load', function () {
        countCharacters(this);
    });

    if (!$('.js-divReason').hasClass('d-none')) {
        displayRelatedReasons();
    }

    $('.js-fileUpload').on('change', function () {
        var files = $(this).prop('files');
        var maxFiles = $('#exceedMaxFiles').data('maxfiles');
        var label = '';

        $('#invalidContactFile').addClass('d-none');
        $('#exceedMaxFiles').addClass('d-none');
        $('.js-fileNames').empty();

        //Check if the number of uploaded files exceeds the limit of attachments that can be added to the form
        if (files.length <= maxFiles) {
            var checkResult = checkFileDimensions(files);

            //If all files do not exceed the 650KB limit, upload the files to SFCC
            if (checkResult.sizeCheck) {
                $('.cc-file-upload__list').empty();
                filesArray = files;
                for (let i = 0; i < files.length; i++) {
                    let file = files[i];
                    label = files[i].name;
                    var fileSection =
                        '<div class="cc-file-upload__item">' +
                        '<div class="cc-file-upload__name">' +
                        label +
                        '</div>' +
                        '<button type="reset" name="' +
                        label +
                        '" class="cc-file-upload__btnRemoveFile js-removeFile" aria-label="rimuovi file">' +
                        '</button>' +
                        '</div>';
                    $('.cc-file-upload__list').append(fileSection);
                    toBase64(file, files.length);
                }
            } else {
                $('#invalidContactFile').removeClass('d-none');
                $('.js-fileNames').append(document.createTextNode('(' + checkResult.notValidFiles.slice(0, -1) + ')'));
                $('.js-fileUpload').val('');
            }
        } else {
            $('#exceedMaxFiles').text($('#exceedMaxFiles').text().replace('{num}', maxFiles));
            $('#exceedMaxFiles').removeClass('d-none');
            $('.js-fileUpload').val('');
        }

        if (label === '') {
            label = resetFileName;
        }
    });

    // TODO - Sostituire la scritta Elimina file con l'icona 'X'
    $(document).on('click', '.js-removeFile', function () {
        var fileNameToRemove = $(this).attr('name');
        var filesUpdated = [];
        var label = '';

        $('.cc-file-upload__list').empty();
        for (let i = 0; i < filesArray.length; i++) {
            let file = filesArray[i];
            if (file.name !== fileNameToRemove) {
                label = file.name;
                var fileSection =
                    '<div class="cc-file-upload__item">' +
                    '<div class="cc-file-upload__name">' +
                    label +
                    '</div>' +
                    '<button type="reset" name="' +
                    label +
                    '" class="cc-file-upload__btnRemoveFile js-removeFile" aria-label="rimuovi file">' +
                    '</button>' +
                    '</div>';
                $('.cc-file-upload__list').append(fileSection);
                filesUpdated.push(file);
            }
        }

        if (filesUpdated.length > 0) {
            filesArray = filesUpdated;
        }

        if (label === '') {
            label = resetFileName;
            $('.cc-file-upload__list').append(label);
        }
    });

    //On person type change update the form fields shown
    $(document).on('change', '.js-selectPersonType', function () {
        selectPersonType();
    });

    //On category selector change
    $(document).on('change', '.js-contactCategory', function () {
        mappedCategories = $('.js-contactCategory').find(':selected').data('mappedcategories');
        $('.js-contactReason').val('');

        $('.js-additionalField').addClass('d-none');
        $('.js-b2bFields').addClass('d-none');
        $('.js-dataTreatment').addClass('d-none');
        handleB2BMember(false);

        //Display the reason selector with the options related to the selected category
        $('.js-divReason').removeClass('d-none');
        displayRelatedReasons();
        //Showing additional fields
        showOrderField();
        showB2bFields();
    });

    $(document).on('change', '.js-contactReason', function () {
        var selectedReason = $('.js-contactReason').find(':selected').val();
        $('.js-dataTreatment').addClass('d-none');
        mappedCategories.forEach((element) => {
            var labels = element.labels ? element.labels.split(',') : [];
            for (let i = 0; i < labels.length; i++) {
                if (labels[i].toUpperCase() === selectedReason.toUpperCase() && element.checkbox === true) {
                    $('.js-dataTreatment').removeClass('d-none');
                    break;
                }
            }
        });
    });

    //Form submit
    $('.js-contactForm').submit(function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();
        removeInvalidation();

        var form = $(this);
        //Get 'valid for' value from selected category and reason
        var categoryValidFor = $('.js-contactCategory').find(':selected').data('validfor');
        var reasonValidFor = $('.js-contactReason').find(':selected').data('validfor');

        var fileNames = [];

        var orderFieldNotPresent = $('.js-additionalField').hasClass('d-none');
        var b2bFieldsNotPresent = $('.js-b2bFields').hasClass('d-none');
        var dataTreatmentNotPresent = $('.js-dataTreatment').hasClass('d-none');

        //If present, get the file names
        if (filesArray.length > 0) {
            for (let i = 0; i < filesArray.length; i++) {
                fileNames.push(filesArray[i].name);
            }
        }

        var url = appendToUrl(form.attr('action'), {
            categoryValidFor: categoryValidFor,
            reasonValidFor: reasonValidFor,
            orderFieldNotPresent: orderFieldNotPresent,
            b2bFieldsNotPresent: b2bFieldsNotPresent,
            dataTreatmentNotPresent: dataTreatmentNotPresent,
            fileNames: fileNames,
        });

        form.spinner().start();
        $.ajax({
            url: url,
            type: 'POST',
            data: form.serialize(),
            success: function (data) {
                if (data.error) {
                    //If the form is invalid then print all the errors
                    if (data.fieldErrors) {
                        data.fieldErrors.forEach(function (error) {
                            if (Object.keys(error).length) {
                                customLoadFormErrors('.js-webToCase', error);
                            }
                        });
                    }
                } else {
                    //alert messages (success if the request was submitted, error if not)
                    const messageType = data.success ? 'alert-success' : 'alert-danger';
                    const message = data.success ? data.messageOk : data.messageError;
                    if ($('.case-submit-messages').length === 0) {
                        $('body').append('<div class="case-submit-messages"></div>');
                    }
                    $('.case-submit-messages').append(`<div class="alert ${messageType} case-submit-alert text-center" role="alert">${message}</div>`);
                    setTimeout(() => {
                        $('.case-submit-alert').remove();
                    }, 5000);
                }
                form.spinner().stop();
            },
            error: function (err) {
                console.log(err);
                form.spinner().stop();
            },
        });
        return false;
    });

    $('#contactPostalCode').on('focus', function () {
        if (!$('#contactAddress1').attr('autocomplete')) {
            initAutocomplete();
        }
    });

    $('#contactPostalCode').on('input', function () {
        if ($(this).val().length >= 5) {
            addressHelpers
                .getBoundsAutocompletePromise($(this).val())
                .then((results) => {
                    autocomplete.setBounds(results[0].geometry.bounds);
                    enableAddressfields();
                    $(this).removeClass('is-invalid').siblings('.invalid-feedback').html('');

                    //Script writing animation
                    const placeholderTexts = ['Via/Piazza...   ', '12   '];
                    const inputFields = [document.getElementById('contactAddress1'), document.getElementById('contactAddressNumber')];

                    setTimeout(addressHelpers.writingAnimation(inputFields, placeholderTexts), 1000);
                })
                .catch(() => {
                    $(this).addClass('is-invalid').siblings('.invalid-feedback').html($(this).data('parse-error'));
                });
        } else {
            disableAddressfields();
        }
    });

    $('#contactAddress1')
        .on('blur', function () {
            if (!isAddressSelected) {
                $('#contactAddress1').val('');
            }
        })
        .on('change', function () {
            isAddressSelected = false;
        });
};
