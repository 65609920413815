module.exports = function () {
    $.fn.selectpicker.Constructor.BootstrapVersion = '4';

    // Select
    if ($('.cc-select.selectpicker').length > 0) {
        $('.cc-select.selectpicker').each(function () {
            $(this).selectpicker('refresh');
        });
    }
};
