'use strict';

module.exports = {
    gtmSearch: function () {
        var searchKeywords = $('.cc-searchField').val();
        var gtmProducts = $('.gtmProductSuggestion');
        var products = [];

        for (var i = 0; i < gtmProducts.length; i++) {
            var product = $(gtmProducts[i]).data('gtmproduct');
            products.push(product);

            if (product.promotion_id && product.promotion_id !== 'undefined') {
                //Send view_suggestion_results event
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    event: 'view_suggestion_results',
                    item_name_copy: product.item_name,
                    item_id_copy: product.item_id,
                    promotion_id: product.promotion_id,
                    keyword_searched: searchKeywords,
                });
            }
        }

        $('.gtmProductSuggestion').each(function () {
            $(this).on('click', function () {
                var product = $(this).data('gtmproduct');
                if (product.promotion_id && product.promotion_id !== 'undefined') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'click_suggested_item',
                        item_name_copy: product.item_name,
                        item_id_copy: product.item_id,
                        promotion_id: product.promotion_id,
                        keyword_searched: $('.cc-searchField').val(),
                    });
                }
            });
        });
    },
};
