'use strict';

/* globals google */

var region = $('#gtmHomeDeliveryForm').data('region');
var language = $('#gtmHomeDeliveryForm').data('language');
var selectedShop = $('.headerselectstore').data('preferredstoreid');
var customerRegistered = $('#gtmHomeDeliveryForm').data('customerregistered');
var customerID = $('#gtmHomeDeliveryForm').data('customerid');
var customerMemberCard = $('#gtmHomeDeliveryForm').data('customermembercard');

//Variables for Google Autocomplete
var isAddressSelected = false;
var selectedCity = '';
var autocomplete;
var addressField;

var addressHelpers = require('../helpers/addressHelpers');

function loadFormErrorsWithoutScrollAnimation(parentSelector, fieldErrors) {
    $.each(fieldErrors, function (attr) {
        $('*[name=' + attr + ']', parentSelector)
            .addClass('is-invalid')
            .siblings('.invalid-feedback')
            .html(fieldErrors[attr]);
    });
}

function hideChangeDeliveryModal() {
    $('#changeDeliveryModal').modal('hide');
}

function hideDeliveryMethodModal() {
    $('#selectStoreModal').modal('hide');
}

function showDeliveryMethodModal() {
    $('#selectStoreModal').modal('show');
}

function hideHomeDeliveryModal() {
    $('#homeDeliveryForm').modal('hide');
}

function showHomeDeliveryModal() {
    $('.form-control').removeClass('is-invalid');
    $('.invalid-feedback').empty();

    $('.cc-modal__input').removeClass('cc-modal__input--noPlaceholder');

    $('#homeDeliveryForm').modal('show');

    //Script writing animation
    const placeholderTexts = ['00000   '];
    const inputFields = [document.getElementById('deliveryPostalCode')];

    setTimeout(addressHelpers.writingAnimation(inputFields, placeholderTexts), 1000);

    //ANALYTICS START
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'form_consegna_inserisci_indirizzo',
        page: {
            page_name: 'inserisci indirizzo',
            page_type: 'metodo di consegna',
            level1: 'inserisci indirizzo',
            level2: 'undefined',
            level3: 'undefined',
            site_region: region,
            site_language: language,
        },
        user: {
            user_status: customerRegistered ? 'loggato' : 'non loggato',
            user_custom_id: customerID ? customerID : 'undefined',
            is_member: customerMemberCard ? 'si' : 'no',
            selected_shop: selectedShop,
        },
        delivery: {
            form_name: 'consegna programmata',
        },
    });
    //ANALYTICS END
}

$('body').on('click', '.cc-modal__close', function () {
    hideDeliveryMethodModal();
    hideHomeDeliveryModal();
    hideChangeDeliveryModal();
});

function selectHomeDeliveryStore(storeId, polygonId) {
    //Select home delivery store
    let selectHomeDeliveryStoreUrl = $('.cc-modal--homedelivery').data('sethomedeliverystoreaction');
    selectHomeDeliveryStoreUrl = `${selectHomeDeliveryStoreUrl}?firstStoreId=${storeId}&polygonId=${polygonId}`;
    $.ajax({
        url: selectHomeDeliveryStoreUrl,
        type: 'get',
        dataType: 'json',
        success: function (data) {
            var redirectUrl = data.redirectUrl;
            location.href = redirectUrl;
        },
        error: function (err) {
            console.log(err);
        },
    });
}

function deleteNotAvailableItems(storeId, polygonId) {
    let removeNotAvailableItemUrl = $('.cc-modal--homedelivery').data('removenotavailableitemsaction');
    $.ajax({
        url: removeNotAvailableItemUrl,
        type: 'post',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify({ nextStoreId: storeId }),
        success() {
            selectHomeDeliveryStore(storeId, polygonId);
        },
    });
}

function checkBasketAvailabilityForDelivery(storeId, polygonId) {
    let url = $('.cc-modal--homedelivery').data('getnotavailableitemsaction');

    $.spinner().start();
    url = `${url}?id=${storeId}`;

    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $.spinner().stop();

            if (data.notAvailableItems.notAvailableItems.quantity > 0) {
                hideHomeDeliveryModal();

                $('.js-ConfirmHomeDeliveryStore').unbind('click');
                $('.js-ConfirmHomeDeliveryStore').click(() => {
                    deleteNotAvailableItems(storeId, polygonId);
                });

                $('.js-DenyHomeDeliveryStore').unbind('click');
                $('.js-DenyHomeDeliveryStore').click(() => {
                    showHomeDeliveryModal();
                });

                $('#changeHomeDeliveryStoreModal').modal();
            } else {
                selectHomeDeliveryStore(storeId, polygonId);
            }
        },
    });
}

function initAutocomplete() {
    addressField = document.getElementById('deliveryAddress');

    autocomplete = new google.maps.places.Autocomplete(addressField, {
        strictBounds: true,
        componentRestrictions: { country: ['it'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
    });

    // When the user selects an address from the drop-down, populate the  address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
}

function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    var cityArray = [];
    let address = '';
    isAddressSelected = true;
    selectedCity = '';

    //Clear input
    $('.cc-modal__input').addClass('cc-modal__input--noPlaceholder');
    $('#deliveryHouseNumber').val('');
    $('#deliveryAddress').val('');

    // Get each component of the address from the place details, and then fill-in the corresponding field on the form.
    for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
            case 'street_number': {
                document.querySelector('#deliveryHouseNumber').value = component.long_name;
                break;
            }

            case 'route': {
                address += component.long_name;
                break;
            }

            case 'locality': {
                selectedCity += component.long_name + ', ';
                cityArray.push(component.long_name);
                break;
            }

            case 'administrative_area_level_3': {
                selectedCity += component.long_name;
                cityArray.push(component.long_name);
                break;
            }
        }
    }
    //controllo sulla city
    if (cityArray[0] === cityArray[1]) {
        selectedCity = cityArray[0];
    }
    addressField.value = address + ', ' + selectedCity;
}

function resetHomeDeliveryModal() {
    $('.js-homeDeliveryFormConfirmAddressTitle').addClass('d-none');
    $('.js-homeDeliveryFormConfirm').addClass('d-none');
    $('.js-homeDeliveryConfirmAddressButton').addClass('d-none');
    $('.js-homeDeliveryFormTitle').removeClass('d-none');
    $('.cc-modal--homedelivery__form').removeClass('d-none');
    $('.js-homeDeliveryFormButton').removeClass('d-none');
    $('.js-homeDeliverySelect').removeClass('d-none');
    $('#shippingAddressSelector').val('new');
    $('#shippingAddressSelector').selectpicker('refresh');

    $('#deliveryPostalCode').val('');
    $('#deliveryHouseNumber').val('');
    $('#deliveryHouseNumber').attr('disabled', true);
    $('#deliveryAddress').val('');
    $('#deliveryAddress').attr('disabled', true);

    showHomeDeliveryModal();
}

// FROM: Inserimento dati consegna a casa
// TO: Conferma indirizzo
function showConfirmAddressModal() {
    $('.js-homeDeliveryFormTitle').addClass('d-none');
    $('.cc-modal--homedelivery__form').addClass('d-none');
    $('.js-homeDeliveryFormButton').addClass('d-none');
    $('.js-homeDeliverySelect').addClass('d-none');
    $('.js-homeDeliveryFormConfirmAddress').text(`${$('#deliveryAddress').val()} ${$('#deliveryHouseNumber').val()}`);
    $('.js-homeDeliveryFormConfirmCity').text(`${selectedCity}, ${$('#deliveryPostalCode').val()}`);
    $('.js-homeDeliveryFormConfirmAddressTitle').removeClass('d-none');
    $('.js-homeDeliveryFormConfirm').removeClass('d-none');
    $('.js-homeDeliveryConfirmAddressButton').removeClass('d-none');

    $('.js-homeDeliveryFormConfirmAddressButton').removeClass('d-none');
    $('.js-homeDeliveryFormAddressNotCoveredButton').addClass('d-none');
}

// FROM: Inserimento dati consegna a casa
// TO: Conferma indirizzo non coperto
function showConfirmAddressNotCoveredModal() {
    $('.js-homeDeliveryFormTitle').addClass('d-none');
    $('.cc-modal--homedelivery__form').addClass('d-none');
    $('.js-homeDeliveryFormButton').addClass('d-none');
    $('.js-homeDeliveryFormConfirmAddress').text(`${$('#deliveryAddress').val()} ${$('#deliveryHouseNumber').val()}`);
    $('.js-homeDeliveryFormConfirmCity').text(`${selectedCity}, ${$('#deliveryPostalCode').val()}`);
    $('.js-homeDeliveryFormConfirmAddressTitle').removeClass('d-none');
    $('.js-homeDeliveryFormConfirm').removeClass('d-none');
    $('.js-homeDeliveryConfirmAddressButton').removeClass('d-none');

    $('.js-homeDeliveryFormConfirmAddressButton').addClass('d-none');
    $('.js-homeDeliveryFormAddressNotCoveredButton').removeClass('d-none');
}

function disableDeliveryfields() {
    $('#deliveryAddress').attr('disabled', true);
    $('#deliveryHouseNumber').attr('disabled', true);
    $('#deliveryAddress').val('');
    $('#deliveryHouseNumber').val('');
}

function enableDeliveryfields() {
    $('#deliveryAddress').attr('disabled', false);
    $('#deliveryHouseNumber').attr('disabled', false);
}

export default function () {
    $('#deliveryPostalCode').on('focus', function () {
        if (!$('#deliveryAddress').attr('autocomplete')) {
            initAutocomplete();
        }
    });

    $('#deliveryPostalCode').on('input', function () {
        if ($(this).val().length >= 5) {
            addressHelpers
                .getBoundsAutocompletePromise($(this).val())
                .then((results) => {
                    autocomplete.setBounds(results[0].geometry.bounds);
                    enableDeliveryfields();
                    $(this).removeClass('is-invalid').siblings('.invalid-feedback').html('');

                    //Script writing animation
                    const placeholderTexts = ['Es. Via Lombardia   ', 'Es. 12   '];
                    const inputFields = [document.getElementById('deliveryAddress'), document.getElementById('deliveryHouseNumber')];

                    setTimeout(addressHelpers.writingAnimation(inputFields, placeholderTexts), 1000);
                })
                .catch(() => {
                    $(this).addClass('is-invalid').siblings('.invalid-feedback').html($(this).data('parse-error'));
                });
        } else {
            disableDeliveryfields();
        }
    });

    $('#deliveryAddress')
        .on('blur', function () {
            if (!isAddressSelected) {
                $('#deliveryAddress').val('');
            }
        })
        .on('change', function () {
            isAddressSelected = false;
        });

    $(document).scroll(function () {
        //Set new top to autocomplete dropdown
        var newTop = $('#deliveryAddress').offset().top + $('#deliveryAddress').outerHeight();
        $('.pac-container').css('top', newTop + 'px');
    });

    // FROM: Selezione metodo di consegna
    // TO: Inserimento dati consegna a casa
    $('.js-btnDeliveryHome').on('click', function () {
        resetHomeDeliveryModal();

        hideDeliveryMethodModal();
        $('#emailSent').modal('hide');

        //ANALYTICS START
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'form_consegna_seleziona_metodo',
            eventInfo: {
                action_name: 'consegna a casa',
            },
        });
        //ANALYTICS END

        showHomeDeliveryModal();
    });

    // FROM: Selezione metodo di consegna
    // TO: Store locator
    $('body').on('click', '.js-btnStorePickup', function () {
        var storeLocatorUrl = $('.js-btnStorePickup').data('url');

        //ANALYTICS START
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'form_consegna_seleziona_metodo',
            eventInfo: {
                action_name: 'ritiro in negozio',
            },
        });
        //ANALYTICS END

        location.href = storeLocatorUrl;
    });

    // FROM: Inserimento dati consegna a casa
    // TO: Selezione metodo di consegna
    $('.js-btnChangeShippingMethod').on('click', function () {
        hideHomeDeliveryModal();
        showDeliveryMethodModal();
    });

    // FROM: Conferma indirizzo
    // TO: Inserimento dati consegna a casa
    $('.js-btnChangeShippingAddress').on('click', function () {
        resetHomeDeliveryModal();
    });

    // FROM: Inserimento email (nessuno store trovato)
    // TO: Inserimento dati consegna a casa
    $('.js-btnBackToHomeDelivery').on('click', function () {
        showHomeDeliveryModal();
        $('#missingStoreForm').modal('hide');
    });

    // FROM: slotpicker (consegna a casa)
    // TO: Inserimento dati consegna a casa
    $(document).on('click', '.js-backToModal', function () {
        showHomeDeliveryModal();
        $('.cc-modal--delivery').find('.js-deliveryCloseBtn').removeClass('d-none');
        $('.cc-modal--delivery').find('.js-deliveryCloseBtn').addClass('js-deliveryCloseModal');
    });

    //CLOSE delivery modal
    $(document).on('click', '.js-deliveryCloseModal', function () {
        hideDeliveryMethodModal();
        hideChangeDeliveryModal();
        $('.js-deliveryCloseBtn').addClass('d-none');
        $('.js-deliveryCloseBtn').removeClass('js-deliveryCloseModal');
    });

    $('.js-homeDeliveryForm').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        form[0].elements.deliveryAddress.value = form[0].elements.deliveryAddress.value.split(', ')[0];
        var dataForm = form.serialize() + '&dwfrm_delivery_city=' + selectedCity;

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: dataForm,
            success: function (data) {
                form.spinner().stop();
                $('.invalid-feedback').empty();
                if (data.error) {
                    if (data.fieldErrors) {
                        data.fieldErrors.forEach(function (error) {
                            if (Object.keys(error).length) {
                                loadFormErrorsWithoutScrollAnimation('.homedelivery-form', error);
                            }
                        });
                    }
                } else {
                    if (!data.statusCode || data.statusCode === '200') {
                        showConfirmAddressModal();
                        $('.js-homeDeliveryFormConfirmAddressButton').on('click', function () {
                            checkBasketAvailabilityForDelivery(data.firstStoreId, data.polygonId);
                        });
                    } else {
                        $('.js-homeDeliverySelect').addClass('d-none');
                        showConfirmAddressNotCoveredModal();
                        $('.js-homeDeliveryFormAddressNotCoveredButton').on('click', function () {
                            hideHomeDeliveryModal();
                            $('.js-homeDeliveryForm')[0].reset();
                            $('.cc-modal__input').removeClass('cc-modal__input--noPlaceholder');
                            $('#missingStoreForm').modal('show');

                            //ANALYTICS START
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                event: 'form_consegna_ko',
                                page: {
                                    page_name: 'form ko',
                                    page_type: 'metodo di consegna',
                                    level1: 'form ko',
                                    level2: 'undefined',
                                    level3: 'undefined',
                                    site_region: region,
                                    site_language: language,
                                },
                                user: {
                                    user_status: customerRegistered ? 'loggato' : 'non loggato',
                                    user_custom_id: customerID ? customerID : 'undefined',
                                    is_member: customerMemberCard ? 'si' : 'no',
                                    selected_shop: selectedShop,
                                },
                                delivery: {
                                    form_name: 'consegna programmata',
                                    reason_ko: 'indirizzo non coperto',
                                },
                            });
                            //ANALYTICS END
                        });
                    }
                }
            },
            error: function (err) {
                console.log(err);
                form.spinner().stop();
            },
        });
        return false;
    });

    $('.js-missingStoreForm').submit(function (e) {
        var form = $(this);
        e.preventDefault();
        var url = form.attr('action');
        form.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: form.serialize(),
            success: function (data) {
                form.spinner().stop();
                if (data.redirectUrl) {
                    location.href = data.redirectUrl;
                }
                if (data.error) {
                    if (data.fieldErrors) {
                        data.fieldErrors.forEach(function (error) {
                            if (Object.keys(error).length) {
                                loadFormErrorsWithoutScrollAnimation('.missingstore-form', error);
                            }
                        });
                    }
                } else {
                    if (data.success) {
                        // FROM: Inserimento email (nessuno store trovato)
                        // TO: Conferma email inviata
                        $('#emailSent').modal('show');
                        $('#missingStoreForm').modal('hide');
                    } else {
                        $('.cc-modal__error').removeClass('d-none');
                        $('.cc-modal__error').modal('show');
                        setTimeout(function () {
                            $('.cc-modal__error').modal('hide');
                            $('.cc-modal__error').addClass('d-none');
                        }, 2000);
                    }
                }
            },
            error: function (err) {
                console.log(err);
                form.spinner().stop();
            },
        });
        return false;
    });

    // automatic fill of address fields on address selection
    $('#shippingAddressSelector').on('change', function () {
        var optionSelected = $('#shippingAddressSelector option:selected');

        if (optionSelected.val() === 'new') {
            $('#deliveryPostalCode').val('');
            $('#deliveryAddress').val('');
            $('#deliveryHouseNumber').val('');
            $('#deliveryHouseNumber').attr('disabled', true);
            $('#deliveryAddress').attr('disabled', true);
            selectedCity = null;
        } else {
            $('#deliveryHouseNumber').attr('disabled', false);
            $('#deliveryAddress').attr('disabled', false);
            $('#deliveryPostalCode').val(optionSelected.data('postal-code'));
            $('#deliveryAddress').val(optionSelected.data('address1') + ', ' + optionSelected.data('city'));
            $('#deliveryHouseNumber').val(optionSelected.data('address-number'));
            selectedCity = optionSelected.data('city');
        }
    });
}
