window.jQuery = require('jquery');
window.$ = require('jquery');
const processInclude = require('base/util');

$(document).ready(() => {
    processInclude(require('./components/selectpicker'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/slider'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/deliveryInfoCatcher'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./consentTracking'));
    processInclude(require('./components/header'));

    processInclude(require('./account/orderDetails'));
    processInclude(require('./account/orderHistory'));
    processInclude(require('./faq/faqPage'));
    processInclude(require('./webToCase/webToCase'));
    processInclude(require('./addressBook/address'));

    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/footer'));

    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('app_gsk/components/scheduledorder'));
    processInclude(require('./storeLocator/selectStoreModal'));
    processInclude(require('base_store/storeLocator/storePopup'));
    processInclude(require('app_gsk/components/sunnieFoodsHeader'));

    processInclude(require('./gtm/gtmHero'));
    processInclude(require('./gtm/gtmInspired'));
    processInclude(require('./gtm/gtmPromotionalCards'));
    processInclude(require('./gtm/gtmCategoryCards'));
    processInclude(require('./gtm/gtmDynamicCategoryBanner'));
    processInclude(require('./gtm/gtmShopInShopList'));
    processInclude(require('./gtm/gtmAutomaticShopInShopListPage'));
});

require('./thirdParty/bootstrap');
require('base_store/components/spinner');
require('slick-carousel/slick/slick');
require('bootstrap-select/js/bootstrap-select');
