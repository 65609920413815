'use strict';
/* globals google */

module.exports = {
    writingAnimation: function (inputFields, placeholderTexts) {
        let currentIndex = 0;

        inputFields.forEach((el) => {
            el.placeholder = '';
        });

        function updatePlaceholder() {
            const currentInputField = inputFields[currentIndex];
            const currentText = placeholderTexts[currentIndex].slice(0, currentInputField.placeholder.length + 1);
            currentInputField.placeholder = currentText;
            if (currentText.length === placeholderTexts[currentIndex].length) {
                currentIndex = (currentIndex + 1) % inputFields.length;
            }
        }

        setInterval(updatePlaceholder, 250);
    },
    getBoundsAutocompletePromise: function (zipCode) {
        var Geocoder = new google.maps.Geocoder();
        return new Promise((resolve, reject) => {
            Geocoder.geocode({ address: zipCode, region: 'IT', componentRestrictions: { country: 'IT' } }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK && results[0].types.includes('postal_code')) {
                    resolve(results);
                } else {
                    reject('Geocoder failed with status: ' + status);
                }
            });
        });
    },
};
