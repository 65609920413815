'use strict';

const creativeName = 'herobanner';

function gtmViewBanner(promotionId, currentSlide) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_promotion',
        creative_name: creativeName,
        creative_slot: currentSlide + 1,
        promotion_name: promotionId && promotionId !== 'undefined' ? `${creativeName}_${promotionId}` : 'undefined',
        promotion_id: promotionId,
    });
}

function gtmClickBanner(promotionId, numberSlide) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'select_promotion',
        creative_name: creativeName,
        creative_slot: numberSlide + 1,
        promotion_name: promotionId && promotionId !== 'undefined' ? `${creativeName}_${promotionId}` : 'undefined',
        promotion_id: promotionId,
    });
}

module.exports = {
    gtmHero: function () {
        $('.cc-hero').each(function () {
            $(this)
                .find('.slick-active')
                .each(function () {
                    var promotionId = $(this).find('.cc-heroItem__img').data('advertising') || 'undefined';
                    var numberSlide = $(this).data('slick-index');

                    if (promotionId && promotionId !== 'undefined') {
                        gtmViewBanner(promotionId, numberSlide);
                    }
                });
        });

        $('.cc-hero').each(function () {
            $(this).on('afterChange', function () {
                $(this)
                    .find('.slick-active')
                    .each(function () {
                        var promotionId = $(this).find('.cc-heroItem__img').data('advertising') || 'undefined';
                        var numberSlide = $(this).data('slick-index');

                        if (promotionId && promotionId !== 'undefined') {
                            gtmViewBanner(promotionId, numberSlide);
                        }
                    });
            });
        });

        $('.cc-hero').each(function () {
            $(this)
                .find('.slick-slide')
                .each(function () {
                    if ($(this).find('.cc-heroItem__elements__btn').length > 0) {
                        var promotionId = $(this).find('.cc-heroItem__img').data('advertising') || 'undefined';
                        var numberSlide = $(this).data('slick-index');
                        $(this)
                            .find('.cc-heroItem__elements__btn')
                            .on('click', function () {
                                if (promotionId && promotionId !== 'undefined') {
                                    gtmClickBanner(promotionId, numberSlide);
                                }
                            });
                    } else {
                        $(this).on('click', function () {
                            var promotionId = $(this).find('.cc-heroItem__img').data('advertising') || 'undefined';
                            var numberSlide = $(this).data('slick-index');

                            if (promotionId && promotionId !== 'undefined') {
                                gtmClickBanner(promotionId, numberSlide);
                            }
                        });
                    }
                });
        });
    },
};
