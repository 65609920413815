'use strict';

/**
 * shows the products for which has been limited the cart addition quantity
 * @param {array} quantityLimitedProducts - the product(s) to show in modal
 * @param {boolean} isList - wether to tune the modal to display a warning for a list or a single product
 * @param {string} redirectUrl - if needed, provide an URL to be redirected to on modal close
 */
function showLimitedProductsModalForOrder(quantityLimitedProducts, redirectUrl) {
    $('#reportLimitedQuantityModal').removeClass('fade');
    $('#reportLimitedQuantityModal').addClass('show');

    $('#reportLimitedQuantityModal .limited-products-list').empty();
    $('.warning-quantity-list').removeClass('d-none');
    $('.reportLimitedQuantityModal-close-btn').remove();

    quantityLimitedProducts.forEach((item) => {
        $('#reportLimitedQuantityModal .limited-products-list').append('<li><p class="cc-modal__subtitle font-weight-bold">&bull;&nbsp;' + item.productName + '</p></li>');
    });

    $('.reportLimitedQuantityModal-confirm-btn').on('click', function (e) {
        e.stopImmediatePropagation();
        $('#reportLimitedQuantityModal').removeClass('show');
        $('#reportLimitedQuantityModal').addClass('fade');

        $('.reportLimitedQuantityModal-confirm-btn').off('click');
        if (redirectUrl) {
            location.href = redirectUrl;
        }
    });
}

module.exports = function () {
    $('.js-openOrderAddAllToCartModal').on('click', function () {
        $('.cc-modal--orderAddAllToCart').modal();
    });

    $('body').on('click', '.js-AddOrderToCart', function () {
        $('.cc-modal--orderAddAllToCart').modal('hide');
        var addAllToCartUrl = $(this).data('url');

        if (addAllToCartUrl) {
            $.ajax({
                url: addAllToCartUrl,
                method: 'GET',
                success: function (data) {
                    // Default store navigation
                    if (data.storeNotSelected) {
                        $('.cc-modal--delivery').modal('show');
                    } else {
                        if (data.error) {
                            $('.cc-warning__alert').removeClass('d-none');
                            $('.cc-warning__text').text(data.error.message);

                            setTimeout(function () {
                                $('.cc-warning__alert').addClass('d-none');
                            }, 5000);
                        }

                        if (data.quantityLimitedProducts && data.quantityLimitedProducts.length > 0) {
                            showLimitedProductsModalForOrder(data.quantityLimitedProducts, data.redirectUrl);
                        } else {
                            location.href = data.redirectUrl;
                        }
                    }

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                },
            });
        }
    });
};
