module.exports = function () {
    // Dinamic width menu
    var categories = [];
    var maxCategoriesCharacters = 0;
    $('.cc-menu__link')
        .find('.cc-menu__link__text')
        .each(function (i) {
            categories[i] = $(this).text();
            i++;
        });

    categories.forEach((el) => {
        if (el.length > maxCategoriesCharacters) {
            maxCategoriesCharacters = el.length;
        }
    });

    $('nav.cc-menu__wrp').css('max-width', `${0.65 * maxCategoriesCharacters}rem`);

    // Open Menu
    $('.js-openMenu').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $('.cc-menu').toggleClass('cc-open');
        $('body').css({ overflow: 'hidden' });
    });

    // Close Menu
    $('.js-closeMenu').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $('.cc-menu').removeClass('cc-open');
        $('.cc-menu__head__btnBack').attr('level', 0);
        $('.cc-menu__head__btnBack').removeClass('cc-menu__head__btnBack--visible');
        $('.cc-menu__child.cc-open').each(function () {
            $(this).removeClass('cc-open');
        });
        $('body').css({ overflow: 'unset' });
    });

    // Go to Second Level
    $('.js-goToChild').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).closest('.cc-menu__item').find('.cc-menu__child:first').addClass('cc-open');
        var level = $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').attr('level');
        level++;
        $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').attr('level', level);
        $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').addClass('cc-menu__head__btnBack--visible');
    });

    // Back to Upper Level
    $('.cc-menu__wrp').on('click', '.cc-menu__head__btnBack--visible', function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).closest('.cc-menu__wrp').find('.cc-menu__child.cc-open:last').removeClass('cc-open');
        var level = $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').attr('level');
        level--;
        $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').attr('level', level);
        if (level === 0) {
            $(this).closest('.cc-menu__wrp').find('.cc-menu__head__btnBack').removeClass('cc-menu__head__btnBack--visible');
        }
    });
};
