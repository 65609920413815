'use strict';
const base = require('base/product/base');
const focusHelper = require('base/components/focus');
const gtm = require('../gtm/gtmAddTo');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    let newUrl = url;
    newUrl +=
        (newUrl.indexOf('?') !== -1 ? '&' : '?') +
        Object.keys(params)
            .map((key) => `${key}=${encodeURIComponent(params[key])}`)
            .join('&');

    return newUrl;
}

/**
 * Retrieves url to use when checking the grand total
 * @param {jquery} $el - DOM container for a given change qty button
 */
function getGetOrderTotalUrl($el) {
    return $($el).closest('.checkout-btn').data('endpoint-get-order-total');
}

function enableGoToCheckoutBtn(isCheckoutEnabled) {
    var $checkoutBtn = $('.checkout-btn');

    if (isCheckoutEnabled) {
        $checkoutBtn.removeClass('cc-disabled');
    } else {
        $checkoutBtn.addClass('cc-disabled');
    }
}
/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            const errorHtml = `${
                '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>'
            }${data.valid.message}</div>`;

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append(`<div class="row"><div class="col-12 text-center"><h1>${data.resources.emptyCartMsg}</h1></div></div>`);
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.cc-minicartTotal__link').attr({
                'aria-label': data.resources.minicartCountOfItems,
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * remove item from cart
 * @param {*} el - jq element of product card to be removed
 */
function removeItem(el) {
    const group = el.closest('.cartgroup');
    el.remove();
    const qty = group.find('.product-info.card').length;
    if (qty > 0) {
        group.find('.groupqty').html(qty);
    } else {
        group.find('.product-info.header').remove();
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.preauth-total').empty().append(data.preAuthTotalAmount);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.cc-minicartTotal__link').attr({
        'aria-label': data.resources.minicartCountOfItems,
    });
    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);

    const getOrderTotalUrl = getGetOrderTotalUrl($('.checkout-btn'));
    var $checkoutLabel = $('.cc-cart__sidebar__button__message');
    var $methodPaymentIcon = $('.js-paymentMethodIcons');
    $.ajax({
        url: getOrderTotalUrl,
        method: 'GET',
        success(data) {
            enableGoToCheckoutBtn(data.isCheckoutEnabled);
            if (!data.isCheckoutEnabled) {
                $checkoutLabel.removeClass('d-none');
                $methodPaymentIcon.addClass('d-none');
            } else {
                $checkoutLabel.addClass('d-none');
                $methodPaymentIcon.removeClass('d-none');
            }
        },
    });

    if (document.getElementsByClassName('original-auth-total').length > 0) {
        const totalPriceEle = document.getElementsByClassName('original-auth-total')[0];
        const origTotalPriceString = totalPriceEle.innerText;
        const origTotalPrice = parseFloat(origTotalPriceString.substr(1));
        const saveEditBtn = document.getElementById('editOrderSaveBtn');
        const paymentEditBTn = document.getElementById('editOrderPaymentBtn');

        if (parseFloat(data.totals.grandTotal.substr(1), 10) > parseFloat(origTotalPrice, 10)) {
            saveEditBtn.style.display = 'none';
            paymentEditBTn.style.display = 'block';
        } else {
            saveEditBtn.style.display = 'block';
            paymentEditBTn.style.display = 'none';
        }
    }

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount').removeClass('d-none');
        $('.order-discount-total').empty().append(`- ${data.totals.orderLevelDiscountTotal.formatted}`);
    } else {
        $('.order-discount').addClass('hide-order-discount');
        $('.order-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount').removeClass('d-none');
        $('.shipping-discount-total').empty().append(`- ${data.totals.shippingLevelDiscountTotal.formatted}`);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
        $('.shipping-discount').addClass('d-none');
    }
    data.items.forEach((item) => {
        if (item.renderedPromotions) {
            $(`.item-${item.UUID}`).empty().append(item.renderedPromotions);
        }
        if (item.priceTotal && item.priceTotal.renderedPrice) {
            $(`.item-total-${item.UUID}`).empty().append(item.priceTotal.renderedPrice);
        }

        $(`.value-${item.UUID}`).empty().append(item.price.sales.formatted);

        var $originalPrice = $(`.original-${item.UUID}`);
        var $originalTotal = $(`.item-total-${item.UUID}`).find('.cc-productPrice--original');
        var originalTotalPrice = $originalTotal.data('originaltotalpriceexists');

        if (item.price.list) {
            if ($originalPrice.hasClass('d-none')) {
                $originalPrice.removeClass('d-none');
                $originalPrice.empty().append(item.price.list.formatted);
                $originalPrice.data('price', item.price.list.value);
            }
        } else {
            if (!originalTotalPrice) {
                $originalTotal.addClass('d-none').empty();
            }
            $originalPrice.addClass('d-none').empty();
            $originalPrice.data('price', null);
        }
    });

    $('.groupqty').each(function (index, value) {
        if (data.groupedItems) {
            const items = data.groupedItems;
            let groupExists = false;
            for (let i = 0; i < items.length; i += 1) {
                const { displayName } = items[i];
                const { groupQty } = items[i];
                if (this.parentNode.innerText.indexOf(displayName) !== -1) {
                    value.innerText = groupQty;
                    groupExists = true;
                    break;
                }
            }
            if (!groupExists) {
                // if there is no more group inside the cart data clear the related DOM elements
                this.parentNode.remove();
            }
        }
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    const errorHtml = `${
        '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>'
    }${message}</div>`;

    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    let html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach((item) => {
            html += `<div class="single-approaching-discount text-center">${item.discountMsg}</div>`;
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    let lineItem;
    let messages = '';

    for (let i = 0; i < data.items.length; i += 1) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $(`.availability-${lineItem.UUID}`).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach((message) => {
                messages += `<p class="line-item-attributes">${message}</p>`;
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += `<p class="line-item-attributes line-item-instock-date">${lineItem.availability.inStockDate}</p>`;
        }
    }

    $(`.availability-${lineItem.UUID}`).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (let i = 0, l = array.length; i < l; i += 1) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

function updateProductsAlreadyInCart(quantity, pid) {
    // console.log("Into updateProductsAlreadyInCart detail");
    // console.log("quantity: ", quantity);
    // console.log("pid: ", pid);
    $(`.cc-addToCart[data-pid=${pid}]`).data('productsalreadyincart', quantity);
}

function disablePlusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--right').attr('disabled', true);
}

function enablePlusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--right').removeAttr('disabled');
}

function disableMinusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--minus').attr('disabled', true);
}

function enableMinusBtn(pid) {
    const addToCartSel = `.cc-addToCart[data-pid=${pid}]`;
    $(addToCartSel).find('.cc-updateQuantity__btn--minus').removeAttr('disabled');
}

function isMaxReached(pid, showMessage) {
    const addToCartSel = '.cc-addToCart[data-pid=' + pid + ']';
    const productsAlreadyInCart = $(addToCartSel).data('productsalreadyincart');
    const maxOrderableQuantity = $(addToCartSel).data('maxorderablequantity');
    var result = false;

    if (maxOrderableQuantity !== '') {
        if (parseInt(productsAlreadyInCart) >= maxOrderableQuantity) {
            result = true;

            if (showMessage) {
                $('.cc-warning').addClass('cc-warning__detailPosition show');
                $('.cc-warning__alert').removeClass('d-none');
                $('.cc-warning__text').text('Quantità massima raggiunta.');

                setTimeout(function () {
                    $('.cc-warning__alert').addClass('d-none');
                    $('.cc-warning').removeClass('show');
                }, 3000);
            }
        }
    }
    return result;
}

function checkButtonsStatus(pid) {
    const addToCartSel = '.cc-addToCart[data-pid=' + pid + ']';
    const productsAlreadyInCart = $(addToCartSel).data('productsalreadyincart');
    const maxOrderableQuantity = $(addToCartSel).data('maxorderablequantity');

    // console.log("productsAlreadyInCart: ", productsAlreadyInCart);
    // console.log("maxOrderableQuantity: ", maxOrderableQuantity);

    enablePlusBtn(pid);

    if (maxOrderableQuantity !== '') {
        if (parseInt(productsAlreadyInCart) >= maxOrderableQuantity) {
            disablePlusBtn(pid);
        }
    }
}

function tilemaxOrderQuantityCheck(categoryWithLimitId, maxOrderQuantity, currentCartQuantity) {
    if (categoryWithLimitId && maxOrderQuantity && currentCartQuantity) {
        var tilesWithDissabledButtons = $('button:disabled').parents('.add-to-cart');
        for (let i = 0; i < tilesWithDissabledButtons.length; i++) {
            var tile = tilesWithDissabledButtons[i];
            var tileCategoryWithLimitId = tile.dataset.categorywithlimitid;
            var tileUnitValue = Number(tile.dataset.unitvalue);
            if (tileCategoryWithLimitId === categoryWithLimitId && currentCartQuantity + tileUnitValue <= maxOrderQuantity) {
                var pid = tile.dataset.pid;
                var isMax = isMaxReached(pid, false);
                if (!isMax) {
                    $(".add-to-cart[data-pid='" + pid + "']")
                        .find('.cc-updateQuantity__btn--right')
                        .prop('disabled', false);
                }
            }
        }
    }
}

/**
 * Updates the quantity of product in the cart.
 * @param {string} url - Url for handling the update
 * @param {string} uuid - UUID of the lineitem
 * @param {number} quantity - the quantity to set
 * @param {number} previousQuantity - the quantity previously selected
 * @param {boolean} decrease - true when the user is decreasing the product quantity
 */
function updateCartQuantity(url, uuid, quantity, previousQuantity, decrease) {
    $(this).parents('.card').spinner().start();
    $.ajax({
        url,
        type: 'get',
        context: this,
        dataType: 'json',
        success(data) {
            $('.cc-warning__alert').addClass('d-none');

            $('.cc-cart__header').find('.js-cartNumItems').html(data.numItems);
            var currentNumProducts = $('.cc-cartProduct.product-info').length;

            if (currentNumProducts !== data.numProducts) {
                window.location.reload();
            } else {
                //Retrieve the updated quantity from the BE data
                var productId = data.pid;
                var currProduct = data.items.filter((item) => item.id === productId);
                //Use as fallback the quantity value evaluated in FE
                var updatedQuantity = currProduct[0].quantity || quantity;
                var $targetProduct = $(`.add-to-cart[data-uuid="${uuid}"]`);
                var minQuantity = $targetProduct.data('min-quantity');
                // Updates selects
                $(`.quantity[data-uuid="${uuid}"]`).val(updatedQuantity);
                $(this).data('pre-select-qty', updatedQuantity);
                // Updates + - buttons
                $targetProduct.find('.update-quantity .quantity').html(updatedQuantity);
                $targetProduct.data('quantity', updatedQuantity);

                if (updatedQuantity === minQuantity) {
                    disableMinusBtn(productId);
                } else {
                    enableMinusBtn(productId);
                }

                var pid = $targetProduct.data('pid');

                updateProductsAlreadyInCart(quantity, pid);
                checkButtonsStatus(pid);
                // when decreasing a product with category maxOrderQuantity, check if there are products of the same category
                // with the + button disabled and enable it if that product quantity can be added to the cart
                // without surpassing the category maxOrderQuantity
                if (decrease && data.maxOrderQuantity) {
                    var categoryWithLimitId = data.categoryWithLimitId ? data.categoryWithLimitId : null;
                    var maxOrderQuantity = data.maxOrderQuantity ? data.maxOrderQuantity : null;
                    var currentCartQuantity = data.currentCartQuantity ? data.currentCartQuantity : null;

                    tilemaxOrderQuantityCheck(categoryWithLimitId, maxOrderQuantity, currentCartQuantity);
                }
            }

            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);
            const { iteminfos } = data;
            $('.cart-json').data('cart', iteminfos);
            $('body').trigger('cart:update');

            $.spinner().stop();

            if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                // eslint-disable-next-line no-restricted-globals
                location.reload();
            }
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                $('.cc-warning').addClass('cc-warning__cartPosition');
                $('.cc-warning__alert').removeClass('d-none');
                $('.cc-warning__alert').children().addClass('show');
                $('.cc-warning__text').text(err.responseJSON.errorMessage);

                setTimeout(function () {
                    $('.cc-warning__alert').addClass('d-none');
                    $('.cc-warning__alert').children().removeClass('show');
                }, 5000);

                if (previousQuantity) {
                    $(this).val(parseInt(previousQuantity, 10));
                }
                $.spinner().stop();
            }
        },
    });
}

// function callUpdateProductList() {
//     var updateCartProductListURL = $('.cc-cart').data('updatecartproductlist');
//     $.ajax({
//         url: updateCartProductListURL,
//         method: 'GET',
//         dataType: 'json',
//         success(data) {
//             updateCartProductList(data);
//         },
//         error() {},
//     });
// }

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    const lineItem = findItem(data.cartModel.items, (item) => item.UUID === uuid);

    if (lineItem.variationAttributes) {
        const colorAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'color');

        if (colorAttr) {
            const colorSelector = `.Color-${uuid}`;
            const newColor = `Color: ${colorAttr.displayValue}`;
            $(colorSelector).text(newColor);
        }

        const sizeAttr = findItem(lineItem.variationAttributes, (attr) => attr.attributeId === 'size');

        if (sizeAttr) {
            const sizeSelector = `.Size-${uuid}`;
            const newSize = `Size: ${sizeAttr.displayValue}`;
            $(sizeSelector).text(newSize);
        }

        const imageSelector = `.card.product-info.uuid-${uuid} .item-image > img`;
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    if (lineItem.options && lineItem.options.length) {
        const option = lineItem.options[0];
        const optSelector = `.lineItem-options-values[data-option-id="${option.optionId}"]`;
        $(optSelector).data('value-id', option.selectedValueId);
        $(`${optSelector} .line-item-attributes`).text(option.displayName);
    }

    const qtySelector = `.quantity[data-uuid="${uuid}"]`;
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $(`.remove-product[data-uuid="${uuid}"]`).data('pid', data.newProductId);

    const priceSelector = `.line-item-price-${uuid} .sales .value`;
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        const listPriceSelector = `.line-item-price-${uuid} .list .value`;
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    const htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="editProductModal" tabindex="-1" role="dialog">' +
        '<span class="enter-message sr-only" ></span>' +
        '<div class="modal-dialog quick-view-dialog">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">' +
        '        <span aria-hidden="true">&times;</span>' +
        '        <span class="sr-only"> </span>' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    const $html = $('<div>').append($.parseHTML(html));

    const body = $html.find('.product-quickview');
    const footer = $html.find('.modal-footer').children();

    return {
        body,
        footer,
    };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success(data) {
            const parsedHtml = parseHtml(data.renderedTemplate);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .modal-header .close .sr-only').text(data.closeButtonText);
            $('#editProductModal .enter-message').text(data.enterDialogMessage);
            $('#editProductModal').modal('show');
            $('body').trigger('editproductmodal:ready');
            $.spinner().stop();
        },
        error() {
            $.spinner().stop();
        },
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    const $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    const $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

// function updateCartProductList(data) {
//     if (data.htmlContent) {
//         $('.cc-cart__prodList').empty().append(data.htmlContent);
//         $('.cc-cart__header').find('.js-cartNumItems').html(data.numItems);
//     }
// }

function preUpdateCartQuantity(element) {
    const productID = $(element).closest('.add-to-cart').data('pid');
    const currentQuantity = parseInt($(element).closest('.add-to-cart').data('quantity'), 10);
    const stepQuantity = parseInt($(element).closest('.add-to-cart').data('step-quantity'), 10) || 1;
    const minQuantity = parseInt($(element).closest('.add-to-cart').data('min-quantity'), 10) || 1;
    const uuid = $(element).closest('.add-to-cart').data('uuid');
    let url = $(element).closest('.add-to-cart').data('action');
    const decrease = $(element).data('decrease') ? $(element).data('decrease') : false;

    if (!decrease) {
        var isMax = isMaxReached(productID, true);
        if (isMax) {
            return;
        }
    }

    var targetQuantity = decrease ? currentQuantity - stepQuantity : currentQuantity + stepQuantity;
    //Case of quantity to be updated less than the minOrderQuantity of the product
    //Set to 0 to be removed from the cart
    if (targetQuantity < minQuantity) {
        targetQuantity = 0;
    }
    var $buttonTarget = $('.cc-cartProduct__header').find('.cc-cartProduct__remove[data-uuid=' + uuid + ']');

    if (targetQuantity <= 0) {
        $buttonTarget.trigger('click');
        return;
    }

    const urlParams = {
        pid: productID,
        quantity: targetQuantity,
        decrease: decrease ? 'true' : 'false',
        uuid,
    };

    // const productsAlreadyInCart = $('.cc-addToCart[data-pid=' + productID + ']').data('productsalreadyincart');
    // const maxOrderableQuantity = $('.cc-addToCart[data-pid=' + productID + ']').data('maxorderablequantity');
    // if (maxOrderableQuantity !== '') {
    //     $('.cc-addToCart[data-pid=' + productID + ']').data('productsalreadyincart', targetQuantity);

    //     if (!decrease && parseInt(productsAlreadyInCart) + 1 >= maxOrderableQuantity) {
    //         //disable plus button
    //         $('.cc-addToCart[data-pid=' + productID + ']')
    //             .find('.cc-updateQuantity__btn--right')
    //             .attr('disabled', true);
    //     }
    //     if (decrease && parseInt(productsAlreadyInCart) - 1 < maxOrderableQuantity) {
    //         //enable plus button
    //         $('.cc-addToCart[data-pid=' + productID + ']')
    //             .find('.cc-updateQuantity__btn--right')
    //             .attr('disabled', false);
    //     }
    // }

    url = appendToUrl(url, urlParams);
    updateCartQuantity(url, uuid, targetQuantity, currentQuantity, decrease);
}

function executeRemoveProduct(url, uuids) {
    $.spinner().start();
    $.ajax({
        url,
        type: 'get',
        dataType: 'json',
        success(data) {
            $('.cc-cart__header').find('.js-cartNumItems').html(data.basket.numItems);

            if (data.basket.items.length === 0) {
                $('.cart').empty().append(`<div class="row"><div class="col-12 text-center"><h1>${data.basket.resources.emptyCartMsg}</h1></div></div>`);
                $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                $('.minicart-quantity').empty().append(data.basket.numItems);
                $('.cc-minicartTotal__link').attr({
                    'aria-label': data.basket.resources.minicartCountOfItems,
                });
                $('.minicart .popover').empty();
                $('.minicart .popover').removeClass('show');
                $('body').removeClass('modal-open');
                $('html').removeClass('veiled');
            } else {
                if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                    for (let i = 0; i < data.toBeDeletedUUIDs.length; i += 1) {
                        $(`.uuid-${data.toBeDeletedUUIDs[i]}`).remove();
                    }
                }

                for (let i = 0; i < uuids.length; i += 1) {
                    var $cartGroup = $(`.uuid-${uuids[i]}`).parent();
                    if ($cartGroup.children().length === 1) {
                        $cartGroup.closest('.cc-cart__group').children('.cc-cart__suggestions__section').remove();
                        $cartGroup.closest('.cc-cart__group').remove();
                    }

                    removeItem($(`.uuid-${uuids[i]}`));
                    $(`.uuid-${uuids[i]}`).remove();
                }

                if (!data.informativeMessageCartIsPresent) {
                    $('.cc-cart__sidebar__informativeMessage').remove();
                }
                if (!data.basket.hasBonusProduct) {
                    $('.bonus-product').remove();
                }
                $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                updateCartTotals(data.basket);
                updateApproachingDiscounts(data.basket.approachingDiscounts);
                $('body').trigger('setShippingMethodSelection', data.basket);
                validateBasket(data.basket);
            }

            const { iteminfos } = data;
            $('.cart-json').data('cart', iteminfos);

            $('body').trigger('cart:update');

            $.spinner().stop();
        },
        error(err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        },
    });
}

function setupMultipleProductRemoval(element, products) {
    var pids = [],
        uuids = [];

    for (let i = 0; i < products.length; i++) {
        var productId = products[i].id ? products[i].id : products[i];
        var productUUID = $('.cc-cartProduct__info[data-pid="' + productId + '"]').data('uuid');
        pids.push(productId);
        uuids.push(productUUID);
    }

    var url = $(element).data('url');
    var urlParams = {
        pid: pids,
        uuid: uuids,
    };

    url = appendToUrl(url, urlParams);
    executeRemoveProduct(url, uuids);
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url,
            method: 'GET',
            success() {
                // reporting urls hit on the server
            },
            error() {
                // no reporting urls hit on the server
            },
        });
    }
}

module.exports = function () {
    $(window).on('load', () => {
        // recupera l'ID dell'ultima sezione visualizzata dal localStorage
        var lastSectionId = localStorage.getItem('lastSectionId');

        // se l'ID è stato salvato, mostra l'ultima sezione visualizzata
        if (lastSectionId) {
            var lastSection = document.getElementById(lastSectionId);
            if (lastSection) {
                lastSection.scrollIntoView();
            }
        }
    });

    // listener per salvare l'ID della sezione visualizzata nel localStorage
    document.addEventListener('scroll', function () {
        var sections = document.querySelectorAll('.cc-cartProduct.product-info');
        for (var i = 0; i < sections.length; i++) {
            var section = sections[i];
            var rect = section.getBoundingClientRect();
            if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                localStorage.setItem('lastSectionId', section.id);
                break;
            }
        }
    });

    $(document).on('click', '.categorySuggestions button.cc-tile-add-to-cart', function () {
        $.spinner().start();
        const el = $(this);

        $('body').trigger('product:beforeAddToCart', this);

        const pid = el.closest('.cc-addToCart').data('pid');
        const daysForShipping = $(this).closest('.cc-addToCart').data('daysforshipping');
        const addToCartUrl = el.closest('.cc-addToCart').data('endpoint-add-to-cart');

        const form = {
            pid,
            daysForShipping,
            quantity: 1,
        };

        //Setting eventSource for addToCart GTM event
        var eventSource = 'recommendations';

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success(data) {
                    if (!data.productAvailabilityResponse) {
                        $('.cc-warning__alert').removeClass('d-none');
                        $('.cc-warning__text').text(data.availabilityMessage);

                        setTimeout(function () {
                            $('.cc-warning__alert').addClass('d-none');
                        }, 8000);
                    } else {
                        if (data.error) {
                            $('.cc-warning__alert').removeClass('d-none');
                            $('.cc-warning__text').text(data.message);

                            setTimeout(function () {
                                $('.cc-warning__alert').addClass('d-none');
                            }, 5000);
                        } else {
                            gtm.gtmAddToCart(data.gtmCartProducts, data.currencyCode, data.totalGrossPrice, eventSource);
                            $('body').trigger('product:afterAddToCart', data);
                            miniCartReportingUrl(data.reportingURL);
                            window.location.reload();
                        }
                    }
                    $.spinner().stop();
                },
                error() {
                    $.spinner().stop();
                },
            });
        }
    });

    //Legal Age products modal logic
    if ($('.cc-cart').hasClass('js-showLegalAgeModal')) {
        window.history.replaceState(null, null, $('.cc-cart').data('carturl'));
        if ($('.cc-cart').hasClass('js-enabledByCustomerTaxCode')) {
            $('#legalAgeProductsAlertModal').modal();
        } else {
            $('#legalAgeProductsModal').modal();
        }
    }

    //AxB/AxC products modal logic
    //Format and display limited availability product names in the modal.
    if ($('.cc-cart').data('limitedavailabilityproducts')) {
        var limitedAvailabilityPrds = $('.cc-cart').data('limitedavailabilityproducts');
        var productsString = '';

        for (let i = 0; i < limitedAvailabilityPrds.length; i++) {
            var productName = limitedAvailabilityPrds[i].name.charAt(0).toUpperCase() + limitedAvailabilityPrds[i].name.slice(1).toLowerCase();
            productsString += productName + '<br>';
        }

        $('#notValidSlotModal').find('.limited-availability-products').append(productsString);
        $('#notValidSlotModal').modal();
    }

    //Unavailable products for selected shipping method modal logic
    //Show the modal only if there are not purchasable products and there's not the "unavailable product modal" first
    if ($('.cc-cart').data('unavailableitemsforshippingmethod') && $('#unavailableProdModal').length === 0) {
        $('#unavailableItemsForSelectedShippingMethodModal').modal();
    }

    $('body').on('change', '.allow-replacement', function (e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const uuid = $(this).data('uuid');
        let val = 'false';
        if ($(this).is(':checked')) {
            val = 'true';
        } else {
            val = 'false';
        }
        let urlParams;
        if (productID && uuid) {
            urlParams = {
                pid: productID,
                uuid,
                val,
            };
        } else {
            urlParams = {
                val,
            };
        }

        const url = appendToUrl(actionUrl, urlParams);

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success() {
                if (uuid && val === 'false') {
                    $('#isReplacementAllowed').prop('checked', false);
                } else if (uuid && val === 'true') {
                    const checkboxes = $('input[type=checkbox][data-uuid]').length;
                    const checkedboxes = $('input[type=checkbox]:checked[data-uuid]').length;
                    if (checkboxes === checkedboxes) {
                        $('#isReplacementAllowed').prop('checked', false);
                    }
                } else if (!uuid && val === 'true') {
                    $('input[type=checkbox][data-uuid]').prop('checked', true);
                } else {
                    $('input[type=checkbox][data-uuid]').prop('checked', false);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        const actionUrl = $(this).data('action');
        const productID = $(this).data('pid');
        const productName = $(this).data('name');
        const uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', (e, data) => {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click((e) => {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-unavailable-close-btn', function (e) {
        e.stopImmediatePropagation();
        $('#unavailableProdModal').removeClass('show');
        $('#unavailableProdModal').addClass('hide');

        //If there are not purchasable products for the selected shipping method show the modal
        if ($('.cc-cart').data('unavailableitemsforshippingmethod')) {
            $('#unavailableItemsForSelectedShippingMethodModal').modal();
        }
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const urlParams = {
            pid: productID,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        executeRemoveProduct(url, [uuid]);
    });

    $('body').on('click', '.js-EmptyCart', function () {
        $('#emptyCartModal').modal('show');
    });


    $('body').on('click', '.js-confirmEmptyCart', function (e) {
        e.preventDefault();

        let url = $(this).data('action');
        url = appendToUrl(url, '');

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                window.location.href = data.redirectUrl;
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('body').on('click', '.remove-limited-availability-products', function () {
        if ($('.cc-cart').data('limitedavailabilityproducts')) {
            setupMultipleProductRemoval(this, $('.cc-cart').data('limitedavailabilityproducts'));
        }
    });

    $('body').on('click', '.remove-unavailable-products-for-shipping-method', function () {
        if ($('.cc-cart').data('unavailableitemsforshippingmethod')) {
            setupMultipleProductRemoval(this, $('.cc-cart').data('unavailableitemsforshippingmethod'));
        }
    });

    document.ondblclick = function (e) {
        e.preventDefault();
    };

    $('body').on('click', '.add-to-cart button.cc-updateQuantity__btn--right', function () {
        const productID = $(this).closest('.add-to-cart').data('pid');
        disablePlusBtn(productID);
        preUpdateCartQuantity(this);
    });

    $('body').on('click', '.add-to-cart button.cc-updateQuantity__btn--left', function () {
        const productID = $(this).closest('.add-to-cart').data('pid');
        disableMinusBtn(productID);
        preUpdateCartQuantity(this);
    });

    $('body').on('change', '.quantity-form > .quantity', function () {
        const currentQuantity = $(this).data('pre-select-qty');
        const targetQuantity = $(this).val();
        const productID = $(this).data('pid');
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');

        const urlParams = {
            pid: productID,
            quantity: targetQuantity,
            uuid,
        };
        url = appendToUrl(url, urlParams);

        updateCartQuantity(url, uuid, targetQuantity, currentQuantity);
    });

    $('.shippingMethods').change(function () {
        const url = $(this).attr('data-actionUrl');
        const urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id'),
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success(data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });

    $('.taxcode-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();

        const $form = $('.taxcode-form');

        $('.js-invalidTaxCode').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    var formName = $('.taxcode-form').attr('name');
                    $('.js-invalidTaxCode').html(data.fieldErrors[0][formName + '_taxcode']);
                } else {
                    $('#legalAgeProductsModal').modal('hide');

                    if (data.checkAgeFromTaxCode) {
                        $('.js-goToCheckout')[0].click();
                    } else {
                        $('#removeLegalProductsAgeModal').modal();
                    }
                }
                $.spinner().stop();
            },
        });
        return false;
    });

    $('.promo-code-form').submit((e) => {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').addClass('d-none');
        $('.coupon-error-message').addClass('d-none');
        $('.cc-coupon-added').addClass('d-none');
        $('.coupon-code-field').removeClass('cc-input-code__error');
        $('.coupon-code-field').removeClass('cc-input-code__ok');

        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-error').removeClass('d-none');
            $('.coupon-missing-error').removeClass('d-none');
            $('.coupon-code-field').val('');
            setTimeout(() => {
                $('.coupon-missing-error').addClass('d-none');
                $('.coupon-error').addClass('d-none');
            }, 7000);
            $.spinner().stop();
            return false;
        }
        const $form = $('.promo-code-form');

        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success(data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);

                    $('.coupon-error').removeClass('d-none');
                    $('.coupon-error-message').removeClass('d-none');
                    $('.coupon-code-field').addClass('cc-input-code__error');
                } else {
                    $('.cc-coupon-added').removeClass('d-none');
                    $('.coupon-code-field').addClass('cc-input-code__ok');
                    setTimeout(() => {
                        $('.cc-coupon-added').addClass('d-none');
                        $('.coupon-code-field').removeClass('cc-input-code__ok');
                    }, 7000);

                    $('.coupon-code-field').val('');
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    updateCartTotals(data);
                    if (data.numItems > Number($('.cc-cart__header').find('.js-cartNumItems').html())) {
                        window.location.reload();
                    }
                }
                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            },
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        const couponCode = $(this).data('code');
        const uuid = $(this).data('uuid');
        const $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        const $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();
        let url = $(this).data('action');
        const uuid = $(this).data('uuid');
        const couponCode = $(this).data('code');
        const urlParams = {
            code: couponCode,
            uuid,
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url,
            type: 'get',
            dataType: 'json',
            success(data) {
                $(`.coupon-uuid-${uuid}`).remove();
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                if (data.numItems < Number($('.cc-cart__header').find('.js-cartNumItems').html())) {
                    window.location.reload();
                }
                updateCartTotals(data);

                $.spinner().stop();
            },
            error(err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            },
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success(data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error() {
                $.spinner().stop();
            },
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', () => {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        const editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('shown.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'true');
        $('#editProductModal .close').focus();
    });

    $('body').on('hidden.bs.modal', '#editProductModal', () => {
        $('#editProductModal').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#editProductModal', (e) => {
        const focusParams = {
            event: e,
            containerSelector: '#editProductModal',
            firstElementSelector: '.close',
            lastElementSelector: '.update-cart-product-global',
            nextToLastElementSelector: '.modal-footer .quantity-select',
        };
        focusHelper.setTabNextFocus(focusParams);
    });

    $('body').on('product:updateAddToCart', (e, response) => {
        // update global add to cart (single products, bundles)
        const dialog = $(response.$productContainer).closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled', !$('.global-availability', dialog).data('ready-to-order') || !$('.global-availability', dialog).data('available'));
    });

    $('body').on('product:updateAvailability', (e, response) => {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);

        const dialog = $(response.$productContainer).closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            const allAvailable = $('.product-availability', dialog)
                .toArray()
                .every((item) => $(item).data('available'));

            const allReady = $('.product-availability', dialog)
                .toArray()
                .every((item) => $(item).data('ready-to-order'));

            $('.global-availability', dialog).data('ready-to-order', allReady).data('available', allAvailable);

            $('.global-availability .availability-msg', dialog)
                .empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', (e, response) => {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        const selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('change', '.options-select', function () {
        const selectedOptionValueId = $(this).children('option:selected').data('value-id');
        $('.modal.show .update-cart-url').data('selected-option', selectedOptionValueId);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        const updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        const selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        const selectedOptionValueId = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-option');
        const uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        const form = {
            uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId,
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success(data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(`.uuid-${data.uuidToBeDeleted}`).remove();
                    }

                    validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error(err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                },
            });
        }
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
