'use strict';

const creativeName = 'categoryCardBanner';
const cardsLength = $('.cc-categoryCards').data('length');
var previousVisibleCards = [];

function getCategoryId(categoryId) {
    return categoryId.toLowerCase().replace(/[',]/g, '').split(' ').join('_');
}

function getCardIndex(el) {
    return $('.cc-categoryCards').find(el.parents()[3]).data('slick-index') + 1;
}

function gtmViewBanner(categoryId, numberSlide) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_promotion',
        creative_name: creativeName,
        creative_slot: numberSlide,
        promotion_name: categoryId && categoryId !== 'undefined' ? `${creativeName}_${categoryId}` : 'undefined',
        promotion_id: categoryId,
    });
}

module.exports = {
    gtmCategoryCard: function () {
        //Init
        var cardsToShow = $('.cc-categoryCards').find('.slick-slider').slick('slickGetOption', 'slidesToShow');
        previousVisibleCards = [0, 1, 2, 3];
        if (cardsToShow === 2) {
            previousVisibleCards = [0, 1];
        }

        $('.cc-categoryCards').each(function () {
            $(this)
                .find('.slick-active .cc-categoryCards__item')
                .each(function () {
                    var categoryId = getCategoryId($(this).data('name'));
                    var cardIndex = getCardIndex($(this));
                    gtmViewBanner(categoryId, cardIndex);
                });
        });

        //After Change listener
        $('.cc-categoryCards')
            .find('.cc-slider')
            .on('afterChange', function () {
                var currentVisibleCards = [];

                //Get indexes for the currently visible cards
                $('.cc-categoryCards')
                    .find('.slick-slide')
                    .each(function () {
                        if ($(this).hasClass('slick-active')) {
                            var cardIndex = $(this).data('slick-index');
                            //Handle cloned cards (infinite slider)
                            if (cardIndex > cardsLength - 1) {
                                cardIndex = cardIndex - cardsLength;
                            }
                            currentVisibleCards.push(cardIndex);
                        }
                    });

                // Compare the current visible slide indexes with the previous ones to find new visible slide indexes
                var newVisibleCards = currentVisibleCards.filter((item) => !previousVisibleCards.includes(item));

                //Trigger vew_promotion event for the new visible cards
                for (let i = 0; i < newVisibleCards.length; i++) {
                    var card = $('.cc-categoryCards').find('[data-slick-index="' + newVisibleCards[i] + '"].slick-slide');
                    var categoryId = getCategoryId(card.find('.cc-categoryCards__item').data('name'));
                    gtmViewBanner(categoryId, newVisibleCards[i] + 1);
                }

                previousVisibleCards = currentVisibleCards;
            });

        //On Click listener
        $('.cc-categoryCards').each(function () {
            $(this)
                .find('.cc-categoryCards__item')
                .each(function () {
                    $(this).on('click', function () {
                        var categoryId = getCategoryId($(this).data('name'));
                        var cardIndex = getCardIndex($(this));

                        //Handle cloned cards (infinite slider)
                        if (cardIndex > cardsLength) {
                            cardIndex = cardIndex - cardsLength;
                        }

                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                            event: 'select_promotion',
                            creative_name: creativeName,
                            creative_slot: cardIndex,
                            promotion_name: categoryId && categoryId !== 'undefined' ? `${creativeName}_${categoryId}` : 'undefined',
                            promotion_id: categoryId,
                        });
                    });
                });
        });
    },
};
