$('.cc-header__actions__user.user.mobile').on('click', function () {
    $('.cc-header__popup').toggleClass('d-none');
});

$(window).on('resize', function () {
    $(".cc-header__popup:not('d-none')").addClass('d-none');
});

$('.page').on('click', function (e) {
    if (!($(e.target).closest('.cc-header__popup').length > 0 || $(e.target).closest('.cc-header__actions__user.user.mobile').length > 0)) {
        $('.cc-header__popup').addClass('d-none');
    }
});

$('.js-Login').on('click', function () {
    //ANALYTICS START
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'login',
        eventInfo: {
            action_name: 'login',
        },
    });
    //ANALYTICS END
});

$('.js-Register').on('click', function () {
    //ANALYTICS START
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'sign_up',
        eventInfo: {
            action_name: 'sign in',
        },
    });
    //ANALYTICS END
});

$('.cc-searchField.cc-input').on('input', function () {
    var inputValue = $('.cc-searchField.cc-input').val();

    //Rimozione simbolo "%" dalla stringa di input
    var sanitizedValue = inputValue.split('%').join('');

    //Aggiornamento del valore dell'input senza il simbolo "%"
    $('.cc-searchField.cc-input').val(sanitizedValue);
});

$('.cc-stripBar__pause').on('click', function () {
    $('.cc-stripBar__slider').slick('slickPause');
    $(this).addClass('d-none');
    $('.cc-stripBar__play').removeClass('d-none');
});

$('.cc-stripBar__play').on('click', function () {
    $('.cc-stripBar__slider').slick('slickPlay');
    $(this).addClass('d-none');
    $('.cc-stripBar__pause').removeClass('d-none');
});

//________________________TO DO________________________________________

// //Show e Hide header on mobile device

// var lastScrollTop = 0;
// var navbarHeight = $('.cc-navbar').offset().top + $('.cc-navbar').outerHeight(true) + 30;
// $(window).on('scroll', function () {
//     if (window.matchMedia('(max-width: 992px)').matches) {
//         // Se la larghezza della finestra è maggiore o uguale a 992px, esegui la funzione
//         var currentScrollTop = $(this).scrollTop();
//         if (currentScrollTop > navbarHeight) {
//             if (currentScrollTop > lastScrollTop) {
//                 // Scroll verso il basso o torna alla posizione originale di .cc-header__fixedContainer
//                 $('.cc-header__fixedContainer').css({
//                     position: 'relative',
//                     opacity: '0',
//                 });
//             } else {
//                 // Scroll verso l'alto
//                 $('.cc-header__fixedContainer').css({
//                     position: 'fixed',
//                     opacity: '1',
//                 });
//             }
//         } else if (currentScrollTop <= $('.cc-navbar').offset().top) {
//             // Torna alla fine della posizione di .cc-navbar
//             $('.cc-header__fixedContainer').css({
//                 position: 'relative',
//                 opacity: '1',
//             });
//         }
//         lastScrollTop = currentScrollTop;
//     } else {
//         $('.cc-header__fixedContainer').css({
//             position: 'relative',
//             opacity: '1',
//         });
//     }
// });
