const cart = require('../cart/cart');
// eslint-disable-next-line
let updateMiniCart = true;

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    $.spinner().stop();
    const status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages').append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

    setTimeout(() => {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
}
/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        $(this).addClass('isInWishlist');
        const url = $(this).attr('data-href');
        const pid = $(this).attr('data-pid');
        let optionId = $(this).closest('.product-info').find('.lineItem-options-values').attr('data-option-id');
        let optionVal = $(this).closest('.product-info').find('.lineItem-options-values').attr('data-value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url,
            type: 'post',
            dataType: 'json',
            data: {
                pid,
                optionId,
                optionVal,
            },
            success(data) {
                displayMessage(data);
            },
            error(err) {
                displayMessage(err);
            },
        });
    });
}

/**
 * add the class to turn the wishlist icon red for the products in wishlist
 * @param {string} productClass - the class name of the product div
 * @param {string} wishlistClass - the class name of the wishlist icon div
 * @param {string} currWishlistListString - as a single string, the list of the product id of the wishlist
 */
function toggleWishlistIcon(productClass, wishlistClass, currWishlistListString) {
    var productsInPage = $(productClass);
    productsInPage.each(function () {
        var currPid = $(this).attr('data-pid');
        currPid = currPid.replaceAll('"', '');

        if (currWishlistListString.includes(currPid)) {
            var wishlistTile = $(this).find(wishlistClass);
            if (wishlistTile.length === 0) {
                wishlistTile = $(this).parents('.product-info').find(wishlistClass);
            }
            wishlistTile.addClass('isInWishlist');
            wishlistTile.attr('aria-label', wishlistTile.data('removelabel'));
            wishlistTile.attr('aria-pressed', 'true');
        }
    });
}

module.exports = function () {
    cart();
    moveToWishlist();
    $('.minicart').on('count:update', (event, data) => {
        if (data && $.isNumeric(data.quantityTotal)) {
            if (data.quantityTotal > 0) {
                $('.minicart-quantity').removeClass('cc-minicart-quantity__hide');
            } else {
                $('.minicart-quantity').addClass('cc-minicart-quantity__hide');
            }
            $('.minicart .minicart-quantity').text(data.numItems);
            $('.minicart .minicart-link').attr({
                'aria-label': data.minicartCountOfItems,
                title: data.minicartCountOfItems,
            });

            // only needed when new products are added, on plp qty increase this isn't needed
            if (data && data.cart && data.cart.items) {
                const itemsquantities = [];
                const { items } = data.cart;

                items.forEach((item) => {
                    itemsquantities.push({ id: item.id, qty: item.quantity });
                });
                $('.cart-json').attr('data-cart', { itemsquantities });
            }
        }
    });

    $('.cc-minicartTotal').on('count:update', (event, data) => {
        $('.cc-minicartTotal__link').attr({
            'aria-label': data.minicartCountOfItems,
        });
    });

    $('body').on('touchstart click', (e) => {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', (event) => {
        if (
            (event.type === 'focusout' && $('.minicart').has(event.target).length > 0) ||
            (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity')) ||
            $('body').hasClass('modal-open')
        ) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        }
    });
    $('body').on('product:afterAddToCart', () => {
        updateMiniCart = true;
    });
    $('body').on('cart:update', () => {
        updateMiniCart = true;
    });

    $(window).on('load', () => {
        var currWishlistListString = $('.js-wishlist-list').data('wishlist-list');

        if (currWishlistListString && currWishlistListString.length > 0) {
            //Check the PDP product
            toggleWishlistIcon('.product-detail', '.add-to-wish-list', currWishlistListString);

            //Check the Tile products
            toggleWishlistIcon('.product', '.wishlistTile', currWishlistListString);

            //Check the Cart products
            toggleWishlistIcon('.gtmCartProduct', '.add-to-wish-list', currWishlistListString);
        }
    });
};
