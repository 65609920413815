'use strict';

const creativeName = 'dynamicCategoryBanner';

function gtmViewBanner(promotionId) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_promotion',
        creative_name: creativeName,
        creative_slot: 1,
        promotion_name: promotionId && promotionId !== 'undefined' ? `${creativeName}_${promotionId}` : 'undefined',
        promotion_id: promotionId,
    });
}

module.exports = {
    gtmHero: function () {
        var promotionId = $('.cc-search__hero').data('advertising') || 'undefined';
        if (promotionId && promotionId !== 'undefined') {
            gtmViewBanner(promotionId);
        }
    },
};
