'use strict';

const creativeName = 'shopInShopCardBanner';

function gtmViewBanner(promotionId, numberSlide) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'view_promotion',
        creative_name: creativeName,
        creative_slot: numberSlide,
        promotion_name: promotionId && promotionId !== 'undefined' ? `${creativeName}_${promotionId}` : 'undefined',
        promotion_id: promotionId,
    });
}

module.exports = {
    gtm: function () {
        var count = 1;
        $('.cc-dynamicCard').each(function () {
            $(this).attr('data-number-banner', `${count}`);
            count = count + 1;
        });

        $('.cc-dynamicCard').each(function () {
            var promotionId = $(this).data('id-advertising') || 'undefined';
            var numberBanner = $(this).data('number-banner');
            if (promotionId && promotionId !== 'undefined') {
                gtmViewBanner(promotionId, numberBanner);
            }
        });

        $('.cc-dynamicCard').each(function () {
            $(this).on('click', function () {
                var promotionId = $(this).data('id-advertising') || 'undefined';
                var numberBanner = $(this).data('number-banner');

                if (promotionId && promotionId !== 'undefined') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'select_promotion',
                        creative_name: creativeName,
                        creative_slot: numberBanner,
                        promotion_name: promotionId && promotionId !== 'undefined' ? `${creativeName}_${promotionId}` : 'undefined',
                        promotion_id: promotionId,
                    });
                }
            });
        });
    },
};
