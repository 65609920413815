/**
 * showSelectStoreModal
 */
function showSelectStoreModal() {
    $('#selectStoreModal').modal({ keyboard: false });
    $('#btn-noStore').click(() => {
        const url = $('#btn-noStore').data('action');

        $.ajax({
            url,
        }).done(() => {
            // redirect to homepage
            const redirectUrl = $('#btn-noStore').data('redirecturl');
            window.location.href = redirectUrl;
        });
    });
}

function openDeliveryModeModal(callbackcheck, callback) {
    $('.cc-modal--delivery').modal('show');
    if (callbackcheck === 'cart') {
        $('#deliveryModal').attr('action', callback);
    }
}

module.exports = {
    init() {
        $('body').on('click', '.js-openDeliveryModeModal', function () {
            let callbackUrl = $(this).data('redirectpageurl');
            let callbackCheck = $(this).data('redirectpage');
            $('.js-deliveryCloseBtn').removeClass('d-none');
            $('.js-deliveryCloseBtn').addClass('js-deliveryCloseModal');
            $('#changeDeliveryModal').modal('hide');

            var closeModalBtnUrl = $('#selectStoreModal').data('modalclosebtnurl');
            if ($('.closeModalBtnContainer .cc-modal__close').length === 0) {
                $.ajax({
                    url: closeModalBtnUrl,
                }).done((data) => {
                    $('.closeModalBtnContainer').html(data);
                });
            }

            openDeliveryModeModal(callbackCheck, callbackUrl);
        });

        $('body').on('click', '.js-openChangeDeliveryModal', function () {
            var url = $(this).data('action');
            $('.js-deliveryCloseBtn').removeClass('d-none');
            $('.js-deliveryCloseBtn').addClass('js-deliveryCloseModal');
            var closeModalBtnUrl = $('#changeDeliveryModal').data('modalclosebtnurl');
            if ($('.closeModalBtnContainer .cc-modal__close').length === 0) {
                $.ajax({
                    url: closeModalBtnUrl,
                }).done((data) => {
                    $('.closeModalBtnContainer').html(data);
                });
            }
            $.spinner().start();
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success(data) {
                    $('.changeDeliveryModalToFill').html(data.changeModal);
                    $('#changeDeliveryModal').modal('show');
                    $.spinner().stop();
                },
            });
        });

        $('body').on('click', '.js-btnDelivery', function () {
            $('#homeDeliveryForm').modal('show');
            $('#changeDeliveryModal').modal('hide');
        });
    },
    showSelectStoreModal(show) {
        if (show) {
            showSelectStoreModal();
        }
    },
};
