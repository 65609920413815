'use strict';

var gtm = require('../gtm/gtmSearch');
var debounce = require('lodash/debounce');
var endpoint = $('.cc-suggestionsWrapper').data('url');
var minChars = 3;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @return {JQuery} - .cc-suggestionsWrapper element
 */
function getSuggestionsWrapper() {
    return $('#suggestionsWrapper');
}

/**
 * Reset search modal
 *
 * @param {boolean} focus - remove focus css class
 * @param {boolean} suggested - remove suggested css class
 */
function resetSearchModal(focus = true, suggested = true) {
    if (focus) {
        $('.cc-contentInputSearch').removeClass('cc-contentInputSearch--focus');
    }
    if (suggested) {
        $('.cc-contentInputSearch').removeClass('cc-contentInputSearch--suggested');
    }

    $('.cc-header__fixedContainer').removeClass('cc-header__openSuggestions');
    $('body').removeClass('modal-open');
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    $.spinner().stop();
    var $suggestionsWrapper = getSuggestionsWrapper().empty();
    $('.cc-btnReset').removeClass('d-none');
    $('.cc-btnSearch').addClass('d-none');

    $('.cc-header__fixedContainer').addClass('cc-header__openSuggestions');
    $('body').addClass('modal-open');

    if (typeof response !== 'object') {
        $suggestionsWrapper.append(response);

        $('.js-searchAllProduct').on('click', function () {
            $('form[name="simpleSearch"]').submit();
        });

        //ANALYTICS START
        gtm.gtmSearch();
        //ANALYTICS END

        // Trigger screen reader by setting aria-describedby with the new suggestion message.
        var suggestionsList = $('.cc-suggestions .cc-suggestions__results__item');
        if ($(suggestionsList).length) {
            $('.cc-contentInputSearch').addClass('cc-contentInputSearch--suggested');
            $('.cc-searchField').attr('aria-describedby', 'search-result-count');
        } else {
            $('.cc-searchField').removeAttr('aria-describedby');
        }
    } else {
        resetSearchModal(false);
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        $.spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $.spinner().stop();
            },
        });
    } else {
        resetSearchModal(false);
        $(scope).siblings('.cc-btnReset').addClass('d-none');
        $(scope).siblings('.cc-btnSearch').removeClass('d-none');
        getSuggestionsWrapper().empty();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.cc-suggestions .cc-suggestions__results__item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('.cc-searchField').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('.cc-searchField').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('.cc-searchField').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

function clickShowMore() {
    $('.js-showMore').click();
    $('.js-showMore').addClass('js-clicked');
}

module.exports = function () {
    $(document).scroll(function () {
        if ($('.js-showMore').length > 0) {
            var elementTop = $('.js-showMore').offset().top + 100;
            var elementBottom = elementTop + $('.js-showMore').outerHeight() - 200;
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();

            if (elementBottom > viewportTop && elementTop < viewportBottom && !$('.js-showMore').hasClass('js-clicked')) {
                clickShowMore();
            }
        }
    });

    $('form[name="simpleSearch"]').on('submit', function (e) {
        var keywords = $(this).find('input[name="q"]').val();

        if (!keywords || keywords.trim() === '') {
            e.preventDefault();
            window.location.href = $(this).data('rootcategory');
            return false;
        }
    });

    $('form[name="simpleSearch"]').submit(function (e) {
        var suggestionsList = $('.cc-suggestions .cc-suggestions__results__item');
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
    });

    $('.cc-searchField').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            $('.cc-contentInputSearch').addClass('cc-contentInputSearch--focus');
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });

    //events to remove focus on the serach bar
    $('.cc-header__actions__user a, .cc-svgIcon--logo, .cc-header__actions__wishlist, .cc-header__actions__btn, .cc-header__delivery__text__btn').on('keyup focus', function () {
        resetSearchModal(true);
    });

    $('body').on('click', function (e) {
        if (!$('.cc-suggestions').has(e.target).length && !$(e.target).hasClass('cc-searchField')) {
            resetSearchModal();
        }
    });

    $('.cc-btnReset').on('click', function (event) {
        event.stopPropagation();
        $(this).addClass('d-none');
        $('.cc-btnSearch').removeClass('d-none');
        $('.cc-header__popup').addClass('d-none');
        $('#searchInputField')[0].value = '';
        $('#searchInputField').trigger('focus');
    });

    // Gestione click "ricerca" nella pagina senza risultati
    $('.js-focus-search').on('click', function (event) {
        event.stopPropagation();
        $('.cc-contentInputSearch').addClass('cc-contentInputSearch--focus');
        $('#searchInputField')[0].value = '';
        $('#searchInputField').trigger('focus');
    });
};
